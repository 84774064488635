import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 109,
      versionNonce: 2102473845,
      isDeleted: false,
      id: 'J1rpLe26M05ZkrzM0vZOJ',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1450,
      y: -110,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 60,
      height: 40,
      seed: 714114287,
      groupIds: ['vKvw7T6m3Fwr1dZlM_s3u'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 172,
      versionNonce: 585251163,
      isDeleted: false,
      id: 'DnHQ8uNleDQzrHJ2B0iDH',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1456.0028971148645,
      y: -97.95725221693864,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 47.99420577027104,
      height: 23.005487063435638,
      seed: 1885017455,
      groupIds: ['vKvw7T6m3Fwr1dZlM_s3u'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 125,
      versionNonce: 511499733,
      isDeleted: false,
      id: 'xfK87ZFmEdzRZzepXnMcG',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1477.4369809472637,
      y: -89.2866972735334,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 5.664377176625166,
      height: 5.664377176625166,
      seed: 1763455105,
      groupIds: ['vKvw7T6m3Fwr1dZlM_s3u'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 132,
      versionNonce: 984110587,
      isDeleted: false,
      id: 'TPn6IMqLk-BPrK8nHoKwW',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1478.0297818516087,
      y: -110,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 3.9404362967827637,
      height: 14.776636112935229,
      seed: 1055559297,
      groupIds: ['vKvw7T6m3Fwr1dZlM_s3u'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
