import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 98,
      versionNonce: 1903733051,
      isDeleted: false,
      id: 'TtSzv3I9hAmAsH3QVN_yf',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 690,
      y: -220,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 10,
      height: 20,
      seed: 1061186854,
      groupIds: ['zQEMDQJv-iErQQ-Xaw425'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 229,
      versionNonce: 2125323253,
      isDeleted: false,
      id: 'dQyscJ5fBInKxKyWdKQmR',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 694.104679860352,
      y: -218.40966481710808,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 1.7906402792958716,
      height: 0,
      seed: 1763105530,
      groupIds: ['zQEMDQJv-iErQQ-Xaw425'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [1.7906402792958716, 0],
      ],
    },
    {
      type: 'ellipse',
      version: 220,
      versionNonce: 1889913307,
      isDeleted: false,
      id: 'GSILCh67clBSDufQGXEMM',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 694.2923965478439,
      y: -202.7045600276716,
      strokeColor: '#1e1e1e',
      backgroundColor: '#1e1e1e',
      width: 1.1152105100471772,
      height: 1.1152105100471772,
      seed: 2070901030,
      groupIds: ['zQEMDQJv-iErQQ-Xaw425'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 149,
      versionNonce: 752391509,
      isDeleted: false,
      id: 'AHSFL0oFZOe71JTgGvs1g',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 691.747302679029,
      y: -216.55175719409104,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 6.505394641942075,
      height: 12.406716924275116,
      seed: 1413308794,
      groupIds: ['zQEMDQJv-iErQQ-Xaw425'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
