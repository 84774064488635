import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 1563,
      versionNonce: 2030960917,
      isDeleted: false,
      id: 'TKacUq4pCXIuyWR59H2GZ',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2195.8467237351333,
      y: -513.7349153964404,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 359.01351947526763,
      height: 150.10096746476708,
      seed: 919500484,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-179.3011716958287, 0.415073056284391],
        [-207.77764972864952, 0.3457647587200696],
        [-225.09804115892192, 2.317167593795698],
        [-230.72549218789493, 5.921650517477926],
        [-250.6773640178901, 8.561480104138973],
        [-257.83957441840136, 19.05226688232034],
        [-264.4506721795741, 40.21044382416865],
        [-265.7691645046816, 76.20906752928134],
        [-265.00178481891254, 101.69791106103426],
        [-259.4138599720988, 126.15690232887796],
        [-249.48182753430785, 139.35682699128466],
        [-229.44652604494655, 141.86214935349338],
        [-223.05169533020444, 146.23902147510753],
        [-190.82174852790428, 147.526336804994],
        [-129.4313736663803, 148.29872600292595],
        [-62.413547775883224, 148.29872600292592],
        [-2.813725514486773, 147.78379987097114],
        [11.510695286535501, 149.07111520085766],
        [42.443039810251804, 148.27562323707096],
        [55.46760442180191, 142.58833301971555],
        [71.36631077652173, 133.36586817624226],
        [82.33577496925872, 122.0911408172016],
        [88.35185661388596, 111.25458928030017],
        [92.81341579589619, 98.29391101779366],
        [93.24435497058602, 52.171814600871585],
        [89.73839280232771, 37.81241654988102],
        [77.76114149126421, 20.85450834416128],
        [66.50623943331811, 12.100764100932997],
        [54.99554414678238, 5.92165051747774],
        [50.902852489347474, 4.376872121613941],
        [45.01960823178477, -1.029852263909417],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 439,
      versionNonce: 1738409659,
      isDeleted: false,
      id: '3rwgo4xNhBobnqwPebdNQ',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0,
      x: 2166.818232067564,
      y: -513.7539087916197,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 17.45972949654405,
      height: 15.984259398244637,
      seed: 1940089156,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-12.541495835545835, -0.2459116830498921],
        [-17.45972949654405, -14.139921775370244],
        [-13.648098409270427, -15.984259398244637],
        [-8.238041382172241, -12.049672469445927],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1051,
      versionNonce: 1849103989,
      isDeleted: false,
      id: 'ZnO27jrAsHj4HNnSEWWQs',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0.009615088299746333,
      x: 2167.8479921353137,
      y: -365.8253522944449,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 18.387046625112603,
      height: 15.678955483101696,
      seed: 1020355780,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.34343278095769, 0.6217369300878647],
        [-18.387046625112603, 13.699197327230582],
        [-14.295546436312444, 15.678955483101696],
        [-8.488255845757223, 11.45547141724332],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1163,
      versionNonce: 88307547,
      isDeleted: false,
      id: 'zYB-wHQvYqlCpLJ76uBu6',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0,
      x: 2139.545928037437,
      y: -451.4218091884124,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 64.35284783065111,
      height: 116.39694355698731,
      seed: 2080519236,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.7393657707927332, -18.05949484575583],
        [-3.0839242896297305, -30.963907194180116],
        [-4.069745317353541, -37.668855661730205],
        [2.2813279156076605, -41.09875136054529],
        [35.15220989258841, -46.555615892633455],
        [48.05338866301964, -44.405419430894966],
        [54.46445892121667, -33.78089832866128],
        [58.50258893695542, -20.857444751436322],
        [60.28310251329757, 5.341540729026359],
        [60.28310251329757, 29.50565355081233],
        [58.50258893695542, 45.02155757322226],
        [53.66976637259833, 59.77438434862842],
        [48.97597908751386, 66.92015013020793],
        [41.67860395384751, 69.84132766435386],
        [26.19898548046265, 69.18567039500822],
        [8.370138240830327, 66.03384525473872],
        [-0.07903825403679478, 63.47444678058547],
        [-3.3778024989828093, 59.535832917101175],
        [-3.0523089880150622, 48.32822564357191],
        [0.27016673314176703, 27.725139974470196],
        [0.40129818701115927, 9.657741303310711],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 637,
      versionNonce: 2086043605,
      isDeleted: false,
      id: 'UeofXE3qcM6IKrSHfvklc',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0,
      x: 1962.4986176359498,
      y: -479.79704335014486,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 26.436161367371923,
      height: 89.05799042199776,
      seed: 1342465404,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-5.8122056906988, 7.312129739911404],
        [-9.74950631988185, 17.81159808439955],
        [-11.43692087524605, 35.43570566264753],
        [-10.874449356791276, 49.872474636318756],
        [-10.686958850639725, 59.62198095620062],
        [-8.999544295275525, 66.74662018996044],
        [-4.499772147637825, 75.55867397908442],
        [-0.37498101230310016, 79.87095562057063],
        [-7.687110752214549, 83.62076574360212],
        [-13.124335430610124, 84.55821827435999],
        [-18.374069602854277, 83.24578473129897],
        [-22.311370232037323, 78.18354106520646],
        [-24.373765799704625, 70.87141132529507],
        [-26.061180355068824, 55.12220880856283],
        [-26.436161367371923, 31.873386045767624],
        [-25.12372782431095, 13.686806949064916],
        [-23.2488227627952, 3.1873386045767567],
        [-19.873993652066925, -1.1249430369094406],
        [-14.436768973671226, -4.4997721476377786],
        [-7.4996202460629995, -3.749810123031484],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 765,
      versionNonce: 2050085883,
      isDeleted: false,
      id: '2joeUO99YapyuzcavxS3M',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0,
      x: 2003.9340194954505,
      y: -499.108565483757,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 82.6893059417941,
      height: 15.16165884852726,
      seed: 1377025092,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [14.624259479822774, -1.6874145553641688],
        [32.24836705807073, -3.3748291107283377],
        [41.62289236564947, -4.499772147637794],
        [47.06011704404518, -5.249734172244088],
        [52.31584394523913, -5.787133540948542],
        [50.99741767322822, -1.3124335430610061],
        [48.66632242120866, 3.7307307022311744],
        [42.93532590871045, 4.499772147637794],
        [26.436161367372048, 6.3746772091535435],
        [8.624563282972424, 7.874601258366131],
        [-11.811901887549151, 9.374525307578718],
        [-20.39641119839642, 8.739833704348259],
        [-30.373461996554976, 4.499772147637794],
        [-22.820701086516678, 3.34975696097811],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 862,
      versionNonce: 1501674805,
      isDeleted: false,
      id: 'GUYT7TiJM3Hd2tSGIZYmN',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0,
      x: 2003.5458659436447,
      y: -379.7044480411019,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 82.6893059417941,
      height: 16.077420089394842,
      seed: 2116555644,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [14.624259479822774, 1.789334065789534],
        [32.24836705807073, 3.578668131579069],
        [41.62289236564947, 4.771557508772102],
        [47.06011704404518, 5.566817093567447],
        [52.31584394523913, 6.136675279452981],
        [50.99741767322822, 1.3917042733918459],
        [48.66632242120866, -3.9560661098768795],
        [42.93532590871045, -4.771557508772101],
        [26.436161367372048, -6.759706470760481],
        [8.624563282972424, -8.35022564035117],
        [-11.811901887549151, -9.94074480994186],
        [-20.39641119839642, -9.267717957518071],
        [-30.373461996554976, -4.771557508772101],
        [-22.820701086516678, -3.5520816288681347],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 600,
      versionNonce: 1181457563,
      isDeleted: false,
      id: '0DCt7vn0wtJrCu2uNNiC3',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0,
      x: 2101.6204492569177,
      y: -372.3725680318224,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 97.7231076650006,
      height: 13.688165789955766,
      seed: 994185412,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [26.163456130168644, 1.3861433711347617],
        [44.876391640487846, 1.2128754497429184],
        [57.52494990209246, 1.559411292526605],
        [58.04475366626806, -2.2524829780939934],
        [47.64867838275746, -4.678233877579815],
        [25.297116523209365, -7.450520619849338],
        [2.079215056702119, -10.222807362118862],
        [-15.247577082482332, -12.128754497429153],
        [-30.321886243572884, -12.128754497429162],
        [-39.67835399873255, -11.782218654645465],
        [-39.505086077340636, -8.143592305416727],
        [-35.51992388532818, -2.2524829780939934],
        [-30.668422086356568, -0.5198037641755298],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 709,
      versionNonce: 1562267285,
      isDeleted: false,
      id: 'fNEUH_plxGWtX0c_lQxB6',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0,
      x: 2101.8817819069654,
      y: -507.2641190117483,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 97.7231076650006,
      height: 13.876500039436957,
      seed: 1418277444,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725568,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [26.163456130168644, -1.4052151938670336],
        [44.876391640487846, -1.2295632946336559],
        [57.52494990209246, -1.5808670931004114],
        [58.04475366626806, 2.2834746900339358],
        [47.64867838275746, 4.742601279301235],
        [25.297116523209365, 7.553031667035303],
        [2.079215056702119, 10.36346205476937],
        [-15.247577082482332, 12.29563294633654],
        [-30.321886243572884, 12.295632946336545],
        [-39.67835399873255, 11.94432914786978],
        [-39.505086077340636, 8.255639263968826],
        [-35.51992388532818, 2.2834746900339358],
        [-30.668422086356568, 0.5269556977001312],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 391,
      versionNonce: 1168331067,
      isDeleted: false,
      id: 'z88TAjxbQ8-nGT2oNQ4z4',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0,
      x: 2281.5976747583272,
      y: -476.63645797268913,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 39.981969638173204,
      height: 30.574447370367757,
      seed: 1559702980,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725568,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-5.608330582730134, -9.588436157570946],
        [-14.654025071004599, -20.262355653734858],
        [-26.23251401599588, -26.413427905761502],
        [-35.09729461450484, -30.574447370367757],
        [-39.981969638173204, -30.574447370367757],
        [-39.25831407911119, -25.87068623646502],
        [-24.423375118341085, -20.262355653734858],
        [-13.025800063115062, -14.111283401708178],
        [-4.703761133902613, -0.5427416692964504],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 547,
      versionNonce: 1440855029,
      isDeleted: false,
      id: 'Odjt42GMSms8yl8cStnqY',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 70,
      angle: 0,
      x: 2280.67898093075,
      y: -401.17214097611594,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 39.981969638173204,
      height: 28.79871061836704,
      seed: 995151484,
      groupIds: ['AGJieQnJlJ64UiPNdTJXC'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725568,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-5.608330582730134, 9.031548300434627],
        [-14.654025071004599, 19.08553603110716],
        [-26.23251401599588, 24.879359469121827],
        [-35.09729461450484, 28.79871061836704],
        [-39.981969638173204, 28.79871061836704],
        [-39.25831407911119, 24.368139754002872],
        [-24.423375118341085, 19.08553603110716],
        [-13.025800063115062, 13.291712593092464],
        [-4.703761133902613, 0.5112197151189253],
        [0, 0],
      ],
    },
  ],
  files: {},
}
export default obj
