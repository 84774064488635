import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

//@ts-ignore
const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 435,
      versionNonce: 2021481917,
      isDeleted: false,
      id: 'CqlxAHL1n3wqGcn5iztB-',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1670.0605693517737,
      y: 318.3013754282375,
      strokeColor: '#f08c00',
      backgroundColor: '#ffec99',
      width: 80,
      height: 51.69862457176248,
      seed: 407342978,
      groupIds: [
        'wXkiQDJ5TDoV4J4Tw_6lf',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [80, 0],
        [69.93943064822633, 51.69862457176248],
        [9.939430648226335, 51.69862457176248],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 376,
      versionNonce: 1591617683,
      isDeleted: false,
      id: '1AWSV3cbQirGPdFGjRaYI',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1674,
      y: 310,
      strokeColor: '#f08c00',
      backgroundColor: '#ffec99',
      width: 80,
      height: 8.30137542823752,
      seed: 1835945794,
      groupIds: ['rpTWxVCLAbuHzeDCbTiWH', 'kplf5JrJesEegWQZ1RHT7', 'tWBu8gbq8pAQNwpEoELLm'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-4, 4],
        [-4, 8.30137542823752],
        [76, 8.30137542823752],
        [75.99668760857344, 4],
        [72, 0],
        [0, 0],
      ],
    },
    {
      type: 'ellipse',
      version: 454,
      versionNonce: 602185245,
      isDeleted: false,
      id: '54Q_SRAYoMJzgn--7VSNV',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1702.4394306482263,
      y: 321.8013754282375,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 15,
      height: 10,
      seed: 925308674,
      groupIds: [
        'n0Sm0yJRqe4tR4tOMRHF0',
        'ikenBGK-Gv5sQmFBkPaOx',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 477,
      versionNonce: 1892785715,
      isDeleted: false,
      id: '5BBl0zr_GQWu3sKZXWjqE',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1703.4394306482263,
      y: 347.8013754282375,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 13,
      height: 7,
      seed: 636544706,
      groupIds: [
        'n0Sm0yJRqe4tR4tOMRHF0',
        'ikenBGK-Gv5sQmFBkPaOx',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 504,
      versionNonce: 1967444605,
      isDeleted: false,
      id: 'FMIbErrcx0fSml4B2SudY',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1702.9394306482263,
      y: 335.8013754282375,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 14,
      height: 8,
      seed: 40550018,
      groupIds: [
        'n0Sm0yJRqe4tR4tOMRHF0',
        'ikenBGK-Gv5sQmFBkPaOx',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 465,
      versionNonce: 201678803,
      isDeleted: false,
      id: 'QCQ82flc9DyQOe0TyYo9q',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1680.4394306482263,
      y: 321.8013754282375,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 15,
      height: 10,
      seed: 693345858,
      groupIds: [
        'BJZfZ6KEp7TTRnlB07zd8',
        'ikenBGK-Gv5sQmFBkPaOx',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 490,
      versionNonce: 694123229,
      isDeleted: false,
      id: 'ElRJQfYXAdY8YFehIoyfD',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1685.4394306482263,
      y: 347.8013754282375,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 13,
      height: 7,
      seed: 126518786,
      groupIds: [
        'BJZfZ6KEp7TTRnlB07zd8',
        'ikenBGK-Gv5sQmFBkPaOx',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 518,
      versionNonce: 982510963,
      isDeleted: false,
      id: 'MCYnGSPqQFbe_W6J7Nzkd',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1682.9394306482263,
      y: 335.8013754282375,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 14,
      height: 8,
      seed: 370046402,
      groupIds: [
        'BJZfZ6KEp7TTRnlB07zd8',
        'ikenBGK-Gv5sQmFBkPaOx',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 492,
      versionNonce: 1757812541,
      isDeleted: false,
      id: 'JZsD1m3Iqbu2LCYJsnOq9',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1724.4394306482263,
      y: 321.8013754282375,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 15,
      height: 10,
      seed: 1442737538,
      groupIds: [
        'DFedbsOAQG8sTL_gPARNJ',
        'ikenBGK-Gv5sQmFBkPaOx',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 517,
      versionNonce: 2142750483,
      isDeleted: false,
      id: 'xX0YlGwn8RI7MJJJlPKRB',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1721.4394306482263,
      y: 347.8013754282375,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 13,
      height: 7,
      seed: 1285123394,
      groupIds: [
        'DFedbsOAQG8sTL_gPARNJ',
        'ikenBGK-Gv5sQmFBkPaOx',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 545,
      versionNonce: 1306142621,
      isDeleted: false,
      id: 'lqYdvePVLH3nASsFzL2AK',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1722.9394306482263,
      y: 335.8013754282375,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 14,
      height: 8,
      seed: 2134799618,
      groupIds: [
        'DFedbsOAQG8sTL_gPARNJ',
        'ikenBGK-Gv5sQmFBkPaOx',
        'rpTWxVCLAbuHzeDCbTiWH',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 919,
      versionNonce: 838036659,
      isDeleted: false,
      id: '1obg9e94OG0zJqn3QpPE0',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1704.6459062374392,
      y: 327.2792321069659,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 10.796089957743332,
      height: 3.4855758390607456,
      seed: 1869382850,
      groupIds: [
        '-Rlc1eRa0b4B1QIipVyAV',
        '3iWebZENRKWhPFaekeuaz',
        '7L0Xr7wL03zatOZ7WWPp7',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 983,
      versionNonce: 718089213,
      isDeleted: false,
      id: 'iNMKz-rDvDpsqHQZNXsCl',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1682.6626672248067,
      y: 327.2699480696157,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 10.796089957743332,
      height: 3.4855758390607456,
      seed: 381852802,
      groupIds: [
        'rT_j702ItPgWwNUQyKdtK',
        'xJakoUPHwt1ij-lpLPJ38',
        'jDV82BPuYSC87XZk1gLIa',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 1021,
      versionNonce: 350838355,
      isDeleted: false,
      id: '9LPI7GD0BCJIFXMs8MDFz',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1726.5748150097315,
      y: 327.2699480696158,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 10.796089957743332,
      height: 3.4855758390607456,
      seed: 1660927042,
      groupIds: [
        'uUm8KDR4ttcLPhWLEkc4e',
        '6mRNjrVq3m43ngIUW9Mqv',
        'arBaw_jWAWXYpAjhWkz9N',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 996,
      versionNonce: 262186077,
      isDeleted: false,
      id: 'sp5DcYowcKlSoH-qkqz6k',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1704.6589632193427,
      y: 339.9150853996707,
      strokeColor: 'transparent',
      backgroundColor: '#ffffff',
      width: 10.796089957743332,
      height: 3.09527932112741,
      seed: 839050242,
      groupIds: [
        'xSsViX6A5inpIUo6Fh1l_',
        'W4koGuQGzlYnvSEKPD3Pj',
        'cfDooJ2sVhXI7LnF0MCEV',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 1012,
      versionNonce: 862985203,
      isDeleted: false,
      id: 'ovrXePfpT_o1cSdQ2EGmw',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1684.734181306153,
      y: 339.97837928463855,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 10.796089957743332,
      height: 3.09527932112741,
      seed: 1305154498,
      groupIds: [
        'gUCwqZChAVSHABR-CjU3V',
        '-AlTD-x1p3K4EJgWkh-ZL',
        '2VMpOux8bTvHDhVJN7QL4',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 1020,
      versionNonce: 270930109,
      isDeleted: false,
      id: 'kUrOoQTtj0ajT7-B-tK67',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1724.854118038189,
      y: 340.1377640286462,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 10.796089957743332,
      height: 3.09527932112741,
      seed: 1752648578,
      groupIds: [
        'PcYONms9pY5SRuI1XNgR7',
        'au-O2O0xxRIi-DwzvpDy1',
        '3mgCWOKRuwRghSxz39Rku',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 1083,
      versionNonce: 2039358867,
      isDeleted: false,
      id: 'mq-_Z7PoaJdhZOMQwE8yi',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1686.8749214582708,
      y: 351.4251400959879,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 10.796089957743332,
      height: 2.5782161218986657,
      seed: 407276354,
      groupIds: [
        'OKwQfifoD7ZYPz7dVQg_E',
        'lxIC6Z2iD_v5fM3I8LBbR',
        'vmji7qm6gF45OI5o01V8f',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 1117,
      versionNonce: 1241444637,
      isDeleted: false,
      id: '58TMeUdN0jeb4cVznUGW1',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1704.4686527043464,
      y: 351.48524851400884,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 10.796089957743332,
      height: 2.5782161218986657,
      seed: 1402458882,
      groupIds: [
        'Mh8-G6k9EE4n0ti8fw3im',
        'E0wyvITyEenCYdzKetvPw',
        'VaUr8jUtpBxQBQarDrQZa',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 1159,
      versionNonce: 242875187,
      isDeleted: false,
      id: 'lFqHV06RzibrVguFJpyJh',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1722.5841490849916,
      y: 351.21490737004933,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 10.796089957743332,
      height: 2.5782161218986657,
      seed: 1827283650,
      groupIds: [
        'gFNAgChMyjzuls41fWx7E',
        'FhWEFC-6tW0jmuE_CmLix',
        'EYbg_o54FRy8iq5RrHHIV',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 562,
      versionNonce: 714917245,
      isDeleted: false,
      id: 'eKl0Z_pdQxNUbrYpOY1Nx',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1702.4267861948638,
      y: 321.7705436561845,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 15,
      height: 10,
      seed: 249330306,
      groupIds: [
        'sW3CNFJagZuGfL0NFuFDk',
        'n_JUwXBtnRz4FYbP0nKfW',
        'KYQf-4d5xAOksyxcJrftD',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 585,
      versionNonce: 1855593683,
      isDeleted: false,
      id: '9dOrw-r-XnzZYqIjZs3W_',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1703.4267861948638,
      y: 347.7705436561845,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 13,
      height: 7,
      seed: 942529090,
      groupIds: [
        'sW3CNFJagZuGfL0NFuFDk',
        'n_JUwXBtnRz4FYbP0nKfW',
        'KYQf-4d5xAOksyxcJrftD',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 612,
      versionNonce: 1967197661,
      isDeleted: false,
      id: '3knNuiAnzk9MeVqAJgogK',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1702.9267861948638,
      y: 335.7705436561845,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 14,
      height: 8,
      seed: 935150082,
      groupIds: [
        'sW3CNFJagZuGfL0NFuFDk',
        'n_JUwXBtnRz4FYbP0nKfW',
        'KYQf-4d5xAOksyxcJrftD',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 573,
      versionNonce: 1166346867,
      isDeleted: false,
      id: 'zXUAE0c9ttLdT5WVqzgaJ',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1680.4267861948638,
      y: 321.7705436561845,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 15,
      height: 10,
      seed: 680112578,
      groupIds: [
        'CWq2bZpZ0L4EsUFIRykow',
        'n_JUwXBtnRz4FYbP0nKfW',
        'KYQf-4d5xAOksyxcJrftD',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 598,
      versionNonce: 150817341,
      isDeleted: false,
      id: '2qpXV8UB-PyFcls-2wdZo',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1685.4267861948638,
      y: 347.7705436561845,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 13,
      height: 7,
      seed: 1840392578,
      groupIds: [
        'CWq2bZpZ0L4EsUFIRykow',
        'n_JUwXBtnRz4FYbP0nKfW',
        'KYQf-4d5xAOksyxcJrftD',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 626,
      versionNonce: 134300691,
      isDeleted: false,
      id: 'OCkXhfbbrNbVvcuI1_Ce4',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1682.9267861948638,
      y: 335.7705436561845,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 14,
      height: 8,
      seed: 779462978,
      groupIds: [
        'CWq2bZpZ0L4EsUFIRykow',
        'n_JUwXBtnRz4FYbP0nKfW',
        'KYQf-4d5xAOksyxcJrftD',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 600,
      versionNonce: 1723005597,
      isDeleted: false,
      id: 'xO59E3kQ4SU2lILN4aID4',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1724.4267861948638,
      y: 321.7705436561845,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 15,
      height: 10,
      seed: 1379086594,
      groupIds: [
        'rB8m7M2CqlhqXxSNnouxe',
        'n_JUwXBtnRz4FYbP0nKfW',
        'KYQf-4d5xAOksyxcJrftD',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 625,
      versionNonce: 1308969395,
      isDeleted: false,
      id: 'Lo9lBUCiInNx2evHlHkcM',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1721.4267861948638,
      y: 347.7705436561845,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 13,
      height: 7,
      seed: 2087305410,
      groupIds: [
        'rB8m7M2CqlhqXxSNnouxe',
        'n_JUwXBtnRz4FYbP0nKfW',
        'KYQf-4d5xAOksyxcJrftD',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 653,
      versionNonce: 1997774589,
      isDeleted: false,
      id: 'QPhkyJoNeFDwwW0XcWLR5',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1722.9267861948638,
      y: 335.7705436561845,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 14,
      height: 8,
      seed: 146299010,
      groupIds: [
        'rB8m7M2CqlhqXxSNnouxe',
        'n_JUwXBtnRz4FYbP0nKfW',
        'KYQf-4d5xAOksyxcJrftD',
        'kplf5JrJesEegWQZ1RHT7',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 694,
      versionNonce: 1823972179,
      isDeleted: false,
      id: 'WmVrZMUeRSjmo1Fa8mvBc',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1704.4324026547356,
      y: 358.9710860880458,
      strokeColor: '#f08c00',
      backgroundColor: '#fff9db',
      width: 11.681682512455824,
      height: 6.290136737476214,
      seed: 1444009950,
      groupIds: [
        'P3MZSXB1AWoBibRG5TQxl',
        'oR9alP7Tjj3lr0SKycGDq',
        'RFdpZajwASMfd6a7Kxrq9',
        'rZx4gsd8u9Dz75q_vR91W',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 707,
      versionNonce: 278968157,
      isDeleted: false,
      id: 'NMd358DQbuk6zKfzuh31R',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1688.2577653297967,
      y: 358.9710860880458,
      strokeColor: '#f08c00',
      backgroundColor: '#fff9db',
      width: 11.681682512455824,
      height: 6.290136737476214,
      seed: 472202398,
      groupIds: [
        'sAuz0zIbDO67HLpRriqvy',
        'oR9alP7Tjj3lr0SKycGDq',
        'RFdpZajwASMfd6a7Kxrq9',
        'rZx4gsd8u9Dz75q_vR91W',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 734,
      versionNonce: 1349433587,
      isDeleted: false,
      id: '7EyFt_aW179C84BAXiIK_',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1720.6070399796743,
      y: 358.9710860880458,
      strokeColor: '#f08c00',
      backgroundColor: '#fff9db',
      width: 11.681682512455824,
      height: 6.290136737476214,
      seed: 875682142,
      groupIds: [
        '4GXnKD7k1tiLhpQSeohHZ',
        'oR9alP7Tjj3lr0SKycGDq',
        'RFdpZajwASMfd6a7Kxrq9',
        'rZx4gsd8u9Dz75q_vR91W',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 1225,
      versionNonce: 1034666941,
      isDeleted: false,
      id: 'HKzaIrIu2zVv2cJxwQ3vj',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1705.789128973609,
      y: 362.6684873802293,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 9.054173452222129,
      height: 1.7769345293589278,
      seed: 1589355074,
      groupIds: [
        'IDjgL5gWavZmZNOFdiULY',
        'aXH4ZAt0ZlcecebuaPNgd',
        'iRIP4ujcxutNiqnDPs2sB',
        '7A_UJNp41SNqo8Ck2FOqU',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 1298,
      versionNonce: 380707475,
      isDeleted: false,
      id: 'E_ifykvIdOzxMHRZ-jwaH',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1722.0269448280171,
      y: 362.6730758975383,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 9.054173452222129,
      height: 1.7769345293589278,
      seed: 1875687134,
      groupIds: [
        'Nse76uKF0xDFi03H__WkV',
        'bwBAnO1hMf9oxggi6NZpn',
        'jzXwI_PyqzI0OAcADxvdX',
        'MVZrzx2uCywl3kxCIX43E',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 1270,
      versionNonce: 1112949789,
      isDeleted: false,
      id: 'wRsq-sal6hZ_rF1izlLl5',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1689.500724968098,
      y: 362.557324225439,
      strokeColor: '#ffffff',
      backgroundColor: '#ffffff',
      width: 9.054173452222129,
      height: 1.7769345293589278,
      seed: 85605698,
      groupIds: [
        '3y-TW252GSoM7Vl_KMz_C',
        't6VCXktDFMID-p7gPAHwk',
        'IVLLRxVuYSJj1CbKHWKZa',
        'vbcH_JK9H9L-dgJh1Efa4',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 836,
      versionNonce: 1748326451,
      isDeleted: false,
      id: 'rDAi3hpwio0Okh6pqu3Vj',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1704.4061406691376,
      y: 358.980861980324,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 11.681682512455824,
      height: 6.290136737476214,
      seed: 120372446,
      groupIds: [
        '_fCOvQv2W3p7wKF0Ncho9',
        'gVldqShkAR7GTuoIdsfO0',
        'zYJX9OuBEhzxU8KepDESh',
        'F67-EdInX5I2iRd12ly4u',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 849,
      versionNonce: 1653717117,
      isDeleted: false,
      id: 'KxM6i970XIPNL4PsaEwu8',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1688.2315033441987,
      y: 358.980861980324,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 11.681682512455824,
      height: 6.290136737476214,
      seed: 1786605854,
      groupIds: [
        'LJbaKI07ggF1NZeuPxoiI',
        'gVldqShkAR7GTuoIdsfO0',
        'zYJX9OuBEhzxU8KepDESh',
        'F67-EdInX5I2iRd12ly4u',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 876,
      versionNonce: 316791251,
      isDeleted: false,
      id: 'cXNs4uIjJa7eqtoeUXNvT',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1720.5807779940765,
      y: 358.980861980324,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 11.681682512455824,
      height: 6.290136737476214,
      seed: 1110285662,
      groupIds: [
        'Mo7M0jFEoWh5yKrA8bDz0',
        'gVldqShkAR7GTuoIdsfO0',
        'zYJX9OuBEhzxU8KepDESh',
        'F67-EdInX5I2iRd12ly4u',
        'tWBu8gbq8pAQNwpEoELLm',
      ],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972271,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
