import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 115,
      versionNonce: 568115925,
      isDeleted: false,
      id: 'Wh_1LfWOM-PcNKbktkn6z',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1140,
      y: -130,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 170,
      height: 70,
      seed: 2144691041,
      groupIds: ['CnInk4In4nlo3kNLNHhow'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 107,
      versionNonce: 157630715,
      isDeleted: false,
      id: 'CyyLn3H6vbA2LwW4Sbaz-',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1150,
      y: -120,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 150,
      height: 50,
      seed: 326238561,
      groupIds: ['CnInk4In4nlo3kNLNHhow'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 103,
      versionNonce: 457678901,
      isDeleted: false,
      id: 'oZWkiOp0gPT6rOO1vwV2d',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1167.1277353548776,
      y: -100,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 10,
      height: 10,
      seed: 240107521,
      groupIds: ['CnInk4In4nlo3kNLNHhow'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 137,
      versionNonce: 1453943195,
      isDeleted: false,
      id: 'B2F57WzL7CGjlO92s-Oq7',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1140,
      y: -97.64402976228006,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 23.52072199915345,
      height: 5.288059524560126,
      seed: 1428749089,
      groupIds: ['CnInk4In4nlo3kNLNHhow'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
