import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 109,
      versionNonce: 1378865557,
      isDeleted: false,
      id: 'VYQzwccql4ic_-M5nmm5B',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1340,
      y: -140,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 90,
      height: 90,
      seed: 1620840783,
      groupIds: ['YcmVB4ws7YGMD2JnPmIIE'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 158,
      versionNonce: 111115835,
      isDeleted: false,
      id: 'vXZqPALTWj8IQ6flNyEcY',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1345.7044646416498,
      y: -134.29553535835032,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 78.59107071670066,
      height: 78.59107071670066,
      seed: 304042497,
      groupIds: ['YcmVB4ws7YGMD2JnPmIIE'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 95,
      versionNonce: 1405802229,
      isDeleted: false,
      id: 'XLXp6uE5XwqdNyJ4nd4Sn',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1380,
      y: -100,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 10,
      height: 10,
      seed: 2004256129,
      groupIds: ['YcmVB4ws7YGMD2JnPmIIE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 152,
      versionNonce: 1143602907,
      isDeleted: false,
      id: 'Uj4yPaqXjI-YoqCUWXjCG',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1360,
      y: -120.2226241334335,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.706650436685322,
      height: 13.706650436685436,
      seed: 1209424655,
      groupIds: ['YcmVB4ws7YGMD2JnPmIIE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [13.706650436685322, 13.706650436685436],
      ],
    },
    {
      type: 'line',
      version: 172,
      versionNonce: 1788751957,
      isDeleted: false,
      id: 'K9lmuDxgT112yU9WOEsPD',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1360,
      y: -69.87641170930479,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.706650436685322,
      height: 13.706650436685436,
      seed: 1065237505,
      groupIds: ['YcmVB4ws7YGMD2JnPmIIE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [13.706650436685322, -13.706650436685436],
      ],
    },
    {
      type: 'line',
      version: 191,
      versionNonce: 970681211,
      isDeleted: false,
      id: 'G9eM0xX4vw0OH0vHxGuze',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1396.4485771868954,
      y: -83.58306214599023,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.706650436685322,
      height: 13.706650436685436,
      seed: 1492091279,
      groupIds: ['YcmVB4ws7YGMD2JnPmIIE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [13.706650436685322, 13.706650436685436],
      ],
    },
    {
      type: 'line',
      version: 194,
      versionNonce: 1954504117,
      isDeleted: false,
      id: 'YZpxGJSBAf1COee_fvJPB',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1396.4485771868954,
      y: -106.51597369674806,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.706650436685322,
      height: 13.706650436685436,
      seed: 1140328545,
      groupIds: ['YcmVB4ws7YGMD2JnPmIIE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [13.706650436685322, -13.706650436685436],
      ],
    },
  ],
  files: {},
}
export default obj
