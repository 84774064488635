import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 191,
      versionNonce: 1160606899,
      isDeleted: false,
      id: 'j4XxJ1t0CFiGV7ktx3o-R',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 761.8689194632361,
      y: 543.3696971377115,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 59.57775005508285,
      height: 50.690771963920675,
      seed: 1750325461,
      groupIds: ['YGWOgrQYRQcSrxx2yTFLX', 'EDoLn2jf8NWG6DFDA4150'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1712860345441,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-1.3109682404462575, 1.8274102745614051],
        [-4.68770461735317, 2.0260418261441586],
        [-8.739788269641515, 1.8671365848779544],
        [-9.931577579138073, -0.754799896014493],
        [-10.05075651008773, -4.647978307036617],
        [-10.60692485451946, -11.878166784649132],
        [-7.389093718878694, -16.526145091685738],
        [-6.991830615713473, -22.92208105265063],
        [-6.952104305396825, -26.219364808924414],
        [-10.527472233886533, -29.99336428899687],
        [-11.83844047433275, -32.93311125242174],
        [-11.242545819584654, -37.73999480072459],
        [-8.96930266464848, -42.54687834902738],
        [-7.022713459137398, -44.849740994974155],
        [-4.903322135814378, -44.153617695475006],
        [-6.091321388071201, -41.40744887397989],
        [-7.534102083599574, -39.214921692090016],
        [-8.263072545843286, -36.98519490471007],
        [-8.706211611545129, -33.98053913739611],
        [-7.319695890381338, -31.3563581441993],
        [-4.959472027503175, -29.42708912525914],
        [-3.221736275556612, -31.818530051253912],
        [0.2780841722154803, -33.40982697622037],
        [7.1110095466625225, -33.96599532065203],
        [14.301471713958703, -34.026998984625735],
        [22.01228105528533, -34.572528652228925],
        [24.16155344848903, -37.422184318192166],
        [25.055287056498514, -41.17021637041546],
        [27.040439819511903, -44.664775133322195],
        [29.9139116683642, -47.71129869017918],
        [34.244079492868394, -48.34691965524404],
        [37.869812408503236, -47.24522164320912],
        [40.60028914351683, -45.28799376086954],
        [42.34824679744513, -42.9044151418764],
        [46.5592356909999, -42.62633096966056],
        [47.7393095807501, -41.56435926794218],
        [47.33365231306037, -38.32973980325308],
        [45.489453709450615, -35.642894518471536],
        [43.07844808458056, -33.98668878961598],
        [39.88131041790365, -33.77126890898485],
        [36.237128545609366, -33.64818483811975],
        [36.150942388063186, -30.628985254061785],
        [35.793405595214296, -26.9344383946224],
        [33.0215419222731, -21.414725772925944],
        [29.414841847990925, -16.057823532411245],
        [27.923691012621354, -11.719261543382846],
        [27.570059359687814, -8.104167304576588],
        [27.490606739054726, -3.138378515007556],
        [30.191995840580127, -1.5493261023454856],
        [31.137617008346446, 0.39950761546993974],
        [29.397469634249074, 2.343852308676638],
        [24.074144051831077, 2.065768136460772],
        [22.417938322975516, -0.07105845610886874],
        [21.7302917431545, -7.826083132360747],
        [21.193156240075435, -14.636070525539548],
        [13.958478737854177, -14.997512458303952],
        [10.079844143575896, -15.233625807898896],
        [4.409620445137486, -16.208334609153237],
        [0.8342525166477786, -13.189135025095272],
        [-1.946589205510877, -11.202819509267645],
        [-4.926062479252288, -9.137051372806933],
        [-5.323325582417873, -3.337010066590299],
        [-0.9931577579136821, -1.946589205510982],
        [0, 0],
      ],
    },
    {
      id: 'exh7CYL2PlSK-L_ZLxjBz',
      type: 'ellipse',
      x: 769.9999999999999,
      y: 515,
      width: 10,
      height: 10,
      angle: 0,
      strokeColor: 'transparent',
      backgroundColor: 'transparent',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['EDoLn2jf8NWG6DFDA4150'],
      frameId: null,
      roundness: null,
      seed: 442113339,
      version: 199,
      versionNonce: 102212605,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345441,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
