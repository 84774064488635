import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 201,
      versionNonce: 1069735317,
      isDeleted: false,
      id: 'rOHzkW-SUctvG9xXUfx_m',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1090.9217543988211,
      y: -230,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 40,
      height: 40.00000000000001,
      seed: 454724911,
      groupIds: ['2fJ6hkRnYyK2EB-kMnnTN'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707050043736,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 242,
      versionNonce: 857498357,
      isDeleted: false,
      id: 'XPE7-DgcFfSUhnRp-G9uR',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1090,
      y: -230,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 41.97493950758689,
      height: 8.78790475066563,
      seed: 438945121,
      groupIds: ['2fJ6hkRnYyK2EB-kMnnTN'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707050043736,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
