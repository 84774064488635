import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

//@ts-ignore
const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      id: 'sMBCtnX3pHxKOE5EQDUZ7',
      type: 'line',
      x: 580.2381047085958,
      y: 893.4334953263342,
      width: 21.30797535120384,
      height: 41.001710145498265,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['PNAWcnLyoDYbUzzr2h2JU', 'Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: { type: 2 },
      seed: 402080862,
      version: 809,
      versionNonce: 117446963,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345442,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [1.614240556909417, -6.456962227637668],
        [14.851013123566345, 4.842721670728079],
        [21.30797535120384, 20.985127239821907],
        [20.01658290567636, 34.5447479178606],
        [8.071202784546914, 30.347722469896215],
        [0, 0],
      ],
      lastCommittedPoint: [0, 0.21399631078861603],
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      type: 'line',
      version: 870,
      versionNonce: 2073003901,
      isDeleted: false,
      id: '7K-r2qSAlqfOQBhtvrWOa',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 580.2381047085958,
      y: 1129.0211889249147,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      width: 21.30797535120393,
      height: 41.00171014549843,
      seed: 1865917826,
      groupIds: ['PNAWcnLyoDYbUzzr2h2JU', 'Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1712860345442,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [1.6142405569094236, 6.456962227637693],
        [14.851013123566407, -4.842721670728098],
        [21.30797535120393, -20.985127239821985],
        [20.016582905676444, -34.54474791786073],
        [8.071202784546946, -30.347722469896336],
        [0, 0],
      ],
    },
    {
      id: 'ha9nHJMhoJGjBP7rLe5CW',
      type: 'rectangle',
      x: 153.89278344941874,
      y: 894.1894590591382,
      width: 333.84634810886644,
      height: 230.23886076473534,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#e9ecef',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['U2l5yBdcrmux0kJeS_G0n', 'Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: { type: 3 },
      seed: 134932638,
      version: 548,
      versionNonce: 1823242963,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345442,
      link: null,
      locked: false,
    },
    {
      id: 'Zhg6qUd3r3xuk7CgqYEg7',
      type: 'line',
      x: 486.473711437592,
      y: 914.6090202609662,
      width: 239.31800517920607,
      height: 198.74956574563055,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#e9ecef',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['U2l5yBdcrmux0kJeS_G0n', 'Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      seed: 716403522,
      version: 2408,
      versionNonce: 1586204637,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345442,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [98.57496469292084, 0],
        [100.48748089394059, 6.2448113058802805],
        [101.92464943138651, 9.369090735110543],
        [103.61176032317086, 11.618571924156551],
        [105.61129915787828, 13.055740461602479],
        [108.17320828984704, 14.055509878956109],
        [110.42268947889288, 14.24296664471],
        [112.17228595926198, 13.993024290370906],
        [114.10933920538464, 13.368168404525402],
        [115.17159421132287, 11.056201626894879],
        [115.42153656566137, 8.994177203603105],
        [115.67147891999977, 6.932152780310987],
        [115.60899333141518, 4.49521482551143],
        [115.23326002278063, 0],
        [180.32445212575828, -0.02439831674499798],
        [195.54799511639322, -4.253160258587974],
        [202.84684157327894, -4.942699984155888],
        [210.1037375629624, -3.423835808168084],
        [216.5456830933103, 0.40318938951488476],
        [221.88799921631744, 6.874645401419311],
        [229.07511131285122, 18.278506982726483],
        [233.19686983626065, 30.701006085555164],
        [236.0915911157935, 42.502068207172385],
        [238.02002538402274, 54.54665157666317],
        [238.15304999523767, 88.97535786187558],
        [238.053608066393, 113.90062060739547],
        [236.31575007161393, 138.8484367752458],
        [232.79165369625656, 157.25981739680412],
        [230.4228635244724, 166.90417738192482],
        [227.0119855964507, 175.89380717480583],
        [224.16248949904303, 180.94771911464508],
        [219.16661662667798, 185.33300057811985],
        [213.3337344280299, 190.0844812058123],
        [205.35862518084747, 193.65485143210068],
        [192.01462288197308, 193.80686576147465],
        [179.88845417025607, 191.7171513425424],
        [151.5688709212256, 191.5862309633191],
        [115.40211867887359, 191.23861530805507],
        [115.61665090919551, 187.80770956738655],
        [115.61665090919551, 186.34933625936532],
        [115.51607343967682, 184.2874981342321],
        [115.16405229636143, 182.32623747861714],
        [114.76174241828656, 181.1193078443928],
        [113.8565451926183, 179.91237821016844],
        [112.39817188459708, 179.2083359235375],
        [110.25085911073236, 178.96260548900128],
        [108.77738298192386, 179.1077584540189],
        [107.72131955197752, 179.35920212781542],
        [106.51438991775308, 179.76151200589052],
        [105.15659407925055, 180.46555429252115],
        [104.2011081188229, 181.27017404867107],
        [103.34619962791395, 182.0245050700613],
        [102.54157987176437, 182.9799910304888],
        [101.83753758513342, 184.03605446043557],
        [100.68089668566837, 186.50020246364323],
        [99.97685439903742, 188.5620405887768],
        [98.55872085952353, 193.21708147155707],
        [45.00406998678096, 192.80714623129782],
        [-1.1649551839684023, 192.80714623129782],
        [-0.4936826507284877, 191.3475482921367],
        [-0.10754387215604311, 189.78885644174716],
        [0.45388567060172136, 186.92924702029177],
        [0.8650992013275527, 184.4725618317475],
        [1.1702668857174103, 180.9459728537022],
        [1.3228507279122823, 177.52339662100985],
        [1.2095181686130445, 173.66097686526382],
        [1.253429358482208, 169.43672314547848],
        [1.243466291973614, 164.7712927763109],
        [1.2934554070657116, 93.74150754643517],
        [1.2531157751840043, 75.22829867572344],
        [1.2531157751846047, 56.21730031952799],
        [1.2531157751845194, 47.124804218161266],
        [1.2531157751845194, 37.86199326466177],
        [1.2531157751846047, 28.662194630570134],
        [1.1901034557731691, 24.12067923109157],
        [1.2392305696490666, 21.730839495298596],
        [1.2294100101408958, 19.218455398912738],
        [1.2559588706094473, 16.195012190769376],
        [1.282608471562753, 10.112174796199042],
        [1.0744177259801972, 6.573420973818202],
        [0.6019661498525011, 3.120848566077484],
        [0, 0],
      ],
      lastCommittedPoint: [-1.1211952901126097, 1.6817929351689145],
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      id: 'uhE4hkaeJo_-4RW3eaj8H',
      type: 'line',
      x: 601.5044101447522,
      y: 914.5289558350987,
      width: 76.75473739806223,
      height: 9.028461684780993,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['qnfpNpUU6a2i9Mg87xq6J', 'Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      seed: 1968335746,
      version: 1020,
      versionNonce: 1302046835,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345442,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [65.49466292810894, 0.019370495979873573],
        [74.18071134411905, -2.4199126431293547],
        [71.39360983439649, -7.1238426978406615],
        [67.78268608716958, -9.009091188801118],
        [-2.574026053943177, -9.009091188801118],
        [-0.8568563679341296, -4.130826670270263],
        [0, 0],
      ],
      lastCommittedPoint: [2.2748863836101236, 2.2748863836102373],
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      type: 'line',
      version: 1186,
      versionNonce: 698846269,
      isDeleted: false,
      id: 'eIMvMYMr4nAhOZDiIFqR4',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 602.0084256584903,
      y: 1106.0191012392277,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      width: 66.72830915553376,
      height: 9.009091188801118,
      seed: 881152606,
      groupIds: ['qnfpNpUU6a2i9Mg87xq6J', 'Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860345442,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [64.40943942799946, 0.29069336119416506],
        [63.208989308539046, 3.9460052697276407],
        [58.63579351395466, 7.378999024249531],
        [51.19752487059513, 8.371200372778945],
        [-2.3188697275343078, 9.009091188801118],
        [-0.8568563679341297, 4.130826670270263],
        [0, 0],
      ],
    },
    {
      id: '7GnzaKKhjiTZioDKV98Yv',
      type: 'line',
      x: 550.0756283613312,
      y: 949.5697504886839,
      width: 72.54994922405751,
      height: 160.61860416449076,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      seed: 410444994,
      version: 878,
      versionNonce: 1335108115,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345442,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [50.82376122113124, -18.622446859650964],
        [59.747017008047386, -2.715773500365794],
        [65.16206950527089, 11.477806690915786],
        [69.05824043787295, 26.381799717839094],
        [72.54994922405751, 54.31547000731588],
        [71.24866460168376, 80.65083558272967],
        [69.5003251577266, 94.1329933324468],
        [67.11840222332592, 105.52719887135638],
        [63.10710630147555, 118.10756404338805],
        [58.5831140793192, 128.80525744592043],
        [52.375631792768765, 141.9961573048398],
        [1.1639029287281852, 122.20980751646039],
        [3.8796764290939794, 82.24914029679233],
        [4.267644072003403, 40.736602505486914],
        [3.1037411432752178, 22.114155645835606],
        [0, 0],
      ],
      lastCommittedPoint: [0, 0.25716007422988696],
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      id: '4doLGVkcizX0_M5veZWK6',
      type: 'line',
      x: 487.7620674410897,
      y: 943.2678408815727,
      width: 82.924979794975,
      height: 6.40956855229372,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      seed: 1046807682,
      version: 601,
      versionNonce: 1019542685,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345442,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [63.09419043664117, 0],
        [82.92379314529981, -6.40956855229372],
        [-0.0011866496751906652, -6.40956855229372],
      ],
      lastCommittedPoint: [-0.1327659950203497, -4.248511840650963],
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      type: 'line',
      version: 770,
      versionNonce: 462186419,
      isDeleted: false,
      id: 'ELTR4i_tXulVWzrhuB5YC',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 487.76088079141425,
      y: 1078.5541898610745,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      width: 83.124092162559,
      height: 6.40956855229372,
      seed: 1187848770,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860345442,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [63.25780244580983, 0],
        [83.08740515446847, 6.40956855229372],
        [-0.03668700809053027, 6.40956855229372],
      ],
    },
    {
      id: 'GMXYzVwjveiSRy_jOLgfn',
      type: 'rectangle',
      x: 497.44684931037636,
      y: 924.2475683909345,
      width: 21.251480706005026,
      height: 5.312870176501235,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: { type: 3 },
      seed: 1513036510,
      version: 513,
      versionNonce: 2140496125,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345442,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 548,
      versionNonce: 2007693651,
      isDeleted: false,
      id: 'uXsh0XAJgzP32i4s8t0L9',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 497.44684931037636,
      y: 1095.1448924017247,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      width: 21.251480706005026,
      height: 5.312870176501235,
      seed: 601582018,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1712860345442,
      link: null,
      locked: false,
    },
    {
      id: 'fkaRnPbr3MEalUabq6VLQ',
      type: 'line',
      x: 609.2945371313585,
      y: 928.7333299325683,
      width: 113.53422562239678,
      height: 164.31630737043395,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#e9ecef',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      seed: 2131672834,
      version: 995,
      versionNonce: 1738160477,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345442,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [7.617312262205594, 17.773728611813084],
        [13.783707903038605, 34.45926975759651],
        [17.410999456469806, 48.60570681597822],
        [20.312832699214805, 64.56578965107579],
        [21.03829100990101, 79.4376850201437],
        [21.03829100990101, 93.94685123386851],
        [19.22464523318541, 111.35785069033815],
        [15.234624524411103, 127.68066268077848],
        [9.7936871942643, 142.18982889450362],
        [4.352749864117495, 153.43443271014016],
        [-1.4509166213724982, 164.31630737043395],
        [33.733811446910224, 163.29155785899812],
        [54.77210245681123, 159.96355750631636],
        [70.36945613656543, 155.9735367975419],
        [77.51604022051151, 153.0960834008854],
        [83.42770572891774, 149.8071411567088],
        [89.90020222763894, 144.59352441091733],
        [95.76049701058395, 138.56253734107224],
        [101.54501127911692, 130.73065649249133],
        [105.91691336019126, 122.60245450597503],
        [109.6778937912602, 112.2468326012563],
        [111.15659970255899, 106.88652367279815],
        [112.08330900102428, 100.83870518538781],
        [111.72057984568117, 76.53585177739853],
        [110.04898977227782, 55.13483161215442],
        [107.81476545155392, 46.95488510262786],
        [103.01508011744635, 38.44929046637099],
        [99.64712894831759, 31.397482239321192],
        [94.67230954455447, 23.94012425264618],
        [89.92375215875074, 18.173689805510353],
        [83.06497657357465, 14.146437058381709],
        [76.69995972493982, 11.561793588604587],
        [68.52960444361018, 8.795143599068282],
        [48.60570681597814, 5.078208174803787],
        [30.46924904882212, 2.1763749320589616],
        [13.25271712814369, 0.646840561967601],
        [0, 0],
      ],
      lastCommittedPoint: [0, 0.24043102103564706],
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      id: '2O5Y7YMO7klbLAnMz07X-',
      type: 'ellipse',
      x: 704.488814377911,
      y: 927.2988987736758,
      width: 6.017647797973571,
      height: 20.05882599324535,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      seed: 149608798,
      version: 485,
      versionNonce: 558257907,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345442,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 547,
      versionNonce: 1042613693,
      isDeleted: false,
      id: 'OfSShYP38ly91WvZDYsdc',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 703.4858730782488,
      y: 1074.3969560574758,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      width: 6.017647797973571,
      height: 20.05882599324535,
      seed: 1125729950,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860345442,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 519,
      versionNonce: 1726283923,
      isDeleted: false,
      id: 'XtqqYL3XOLJhxz4IZ0Z6d',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 710.8407759424388,
      y: 1060.578653706573,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      width: 5.349020264865402,
      height: 17.83006754955144,
      seed: 99303874,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860345442,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 590,
      versionNonce: 643253789,
      isDeleted: false,
      id: 'Aj5gfnxy0ZRX91Wn0jxeR',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 711.8437172421011,
      y: 945.4632800897816,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      width: 5.349020264865402,
      height: 17.83006754955144,
      seed: 98787458,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860345442,
      link: null,
      locked: false,
    },
    {
      id: 'y8hlCVJZH21-xg3W32uLH',
      type: 'line',
      x: 263.97906445243825,
      y: 1124.4753097049731,
      width: 92.29473850877125,
      height: 7.4887442732545235,
      angle: 0,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      seed: 1397480834,
      version: 752,
      versionNonce: 37582387,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345443,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [92.29473850877125, 0],
        [90.78788563515866, 3.7671321840315337],
        [87.39746666953037, 6.40412471285347],
        [81.9436984922648, 7.4887442732545235],
        [8.664404023272407, 7.4887442732545235],
        [4.332202011636204, 6.780837931256555],
        [1.5068528736126179, 3.9554887932330764],
        [0, 0],
      ],
      lastCommittedPoint: [-0.12485010152067844, 0.12485010152067844],
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      type: 'line',
      version: 806,
      versionNonce: 1663203965,
      isDeleted: false,
      id: 'KnTo45ZJ94WLlv3wnQ07C',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 262.28385496962403,
      y: 893.9758017297486,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ced4da',
      width: 92.29473850877129,
      height: 7.488744273254526,
      seed: 1037742430,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860345443,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [92.29473850877129, 0],
        [90.7878856351587, -3.767132184031535],
        [87.39746666953042, -6.4041247128534735],
        [81.94369849226484, -7.488744273254526],
        [8.664404023272413, -7.488744273254526],
        [4.332202011636206, -6.780837931256558],
        [1.5068528736126185, -3.9554887932330782],
        [0, 0],
      ],
    },
    {
      id: 'EPekZbdYWMTXXMPdSlxgd',
      type: 'line',
      x: 189.2743615110918,
      y: 921.742103066892,
      width: 264.4863008703409,
      height: 0,
      angle: 0,
      strokeColor: '#343a40',
      backgroundColor: 'transparent',
      fillStyle: 'solid',
      strokeWidth: 4,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      seed: 1090010050,
      version: 493,
      versionNonce: 842414035,
      isDeleted: false,
      boundElements: null,
      updated: 1712860345443,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [264.4863008703409, 0],
      ],
      lastCommittedPoint: null,
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      type: 'line',
      version: 537,
      versionNonce: 1565008605,
      isDeleted: false,
      id: '_DVPDSkh3puxKgJ1nhUV_',
      fillStyle: 'solid',
      strokeWidth: 4,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 189.2743615110918,
      y: 1094.801040673411,
      strokeColor: '#343a40',
      backgroundColor: 'transparent',
      width: 264.4863008703409,
      height: 0,
      seed: 1587315934,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860345443,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [264.4863008703409, 0],
      ],
    },
    {
      type: 'line',
      version: 544,
      versionNonce: 350602611,
      isDeleted: false,
      id: 'WdmT8FuUaX87zTBggvQdr',
      fillStyle: 'solid',
      strokeWidth: 4,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 189.2743615110918,
      y: 977.2515736199266,
      strokeColor: '#343a40',
      backgroundColor: 'transparent',
      width: 264.4863008703409,
      height: 0,
      seed: 720704706,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860345443,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [264.4863008703409, 0],
      ],
    },
    {
      type: 'line',
      version: 570,
      versionNonce: 1767489341,
      isDeleted: false,
      id: 'SEEebC9dowTDJSv_DR4aK',
      fillStyle: 'solid',
      strokeWidth: 4,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 189.2743615110918,
      y: 1039.2915701203763,
      strokeColor: '#343a40',
      backgroundColor: 'transparent',
      width: 264.4863008703409,
      height: 0,
      seed: 893276190,
      groupIds: ['Whh3dMviTVn4Jy2Oe7cuY'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860345443,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [264.4863008703409, 0],
      ],
    },
  ],
  files: {},
}
export default obj
