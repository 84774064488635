import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 309,
      versionNonce: 835694261,
      isDeleted: false,
      id: 'hyoq3eP5eiycqMUsoGG96',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1082.559773890288,
      y: -286.1674194173951,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 60.23370016677801,
      height: 39.5325996664439,
      seed: 1520470075,
      groupIds: ['tERIm2FwJxuXwFyDgKCJ2'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707049934456,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 230,
      versionNonce: 1464828699,
      isDeleted: false,
      id: 'mU-r8x1NVU4U-vBoE93tf',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1132.8530670800005,
      y: -300.28286792329584,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 15.059593022934735,
      height: 43.648048172344666,
      seed: 1754249307,
      groupIds: ['tERIm2FwJxuXwFyDgKCJ2'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707049934456,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 212,
      versionNonce: 699535381,
      isDeleted: false,
      id: 'vW9Wpa4nJihHgsxvp760t',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1076.8540014649927,
      y: -300.28286792329584,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 15.765365448229772,
      height: 43.64804817234466,
      seed: 1173041723,
      groupIds: ['tERIm2FwJxuXwFyDgKCJ2'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707049934456,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 246,
      versionNonce: 239495099,
      isDeleted: false,
      id: '9nQKsmGAFzSvR1set_3OQ',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1082.0877016317709,
      y: -306.28193353830363,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 61.17784468381201,
      height: 20,
      seed: 2037606389,
      groupIds: ['tERIm2FwJxuXwFyDgKCJ2'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707049934456,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
