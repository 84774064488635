import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 116,
      versionNonce: 1438495931,
      isDeleted: false,
      id: 'aximTsaGyRknq_gV7fcM3',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 530,
      y: -230,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 40,
      height: 30,
      seed: 186300027,
      groupIds: ['ko-1S9gREWSwOgLJV-LCK'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725564,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 178,
      versionNonce: 900810869,
      isDeleted: false,
      id: '1aQTxunQk5IfitVYg8pzF',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 530,
      y: -230,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 49.01386831471984,
      height: 10,
      seed: 602676789,
      groupIds: ['ko-1S9gREWSwOgLJV-LCK'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-4.899296003262748, -10],
        [44.114572311457096, -10],
        [40, 0],
        [0, 0],
      ],
    },
    {
      type: 'rectangle',
      version: 137,
      versionNonce: 792552795,
      isDeleted: false,
      id: 'HFKfaox1QTITJ_WdPT_zI',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 60,
      angle: 0,
      x: 533.9617936734455,
      y: -225.89862619286845,
      strokeColor: 'transparent',
      backgroundColor: '#ff8787',
      width: 32.07641265310883,
      height: 11.518348179980023,
      seed: 1534274005,
      groupIds: ['ko-1S9gREWSwOgLJV-LCK'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 256,
      versionNonce: 2042261973,
      isDeleted: false,
      id: 'E30LpJxCbUEX_z-7F1eiT',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 60,
      angle: 0,
      x: 542.7099974168716,
      y: -210.78973033719126,
      strokeColor: 'transparent',
      backgroundColor: '#ff8787',
      width: 14.580005166256676,
      height: 7.5418919329681575,
      seed: 1374544667,
      groupIds: ['ko-1S9gREWSwOgLJV-LCK'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
