import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 134,
      versionNonce: 1790837243,
      isDeleted: false,
      id: 'AIE5gsg0fGoBf3UNmKTTv',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 600,
      y: -229.3071250565397,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 20,
      height: 30,
      seed: 856540085,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 179,
      versionNonce: 697790261,
      isDeleted: false,
      id: '5NYIdmcARsnUkFTV2ImrN',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 603.1308665247949,
      y: -225.78490021614542,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 9.23764632101745,
      height: 0,
      seed: 276703061,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [9.23764632101745, 0],
      ],
    },
    {
      type: 'line',
      version: 286,
      versionNonce: 1900153499,
      isDeleted: false,
      id: 'tLZYKp9aGEI2sYYQARaVK',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 608.5069489243374,
      y: -219.8163890110619,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 9.23764632101745,
      height: 0,
      seed: 893491509,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [9.23764632101745, 0],
      ],
    },
    {
      type: 'line',
      version: 268,
      versionNonce: 1135460501,
      isDeleted: false,
      id: 'gMzfUjWWVf6rdY1TFihUt',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 603.0926877002148,
      y: -214.5037334712711,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 9.23764632101745,
      height: 0,
      seed: 434569045,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [9.23764632101745, 0],
      ],
    },
    {
      type: 'line',
      version: 257,
      versionNonce: 1736179515,
      isDeleted: false,
      id: 'VhrhAg1iIxJwb-73Yf8fA',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 607.7570186907229,
      y: -209.41154879498885,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 9.23764632101745,
      height: 0,
      seed: 1545002555,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [9.23764632101745, 0],
      ],
    },
    {
      type: 'line',
      version: 236,
      versionNonce: 1572005365,
      isDeleted: false,
      id: 'US9yXqdraZSMKV3QPS1bh',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 602.6813312822742,
      y: -203.70843257226895,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 9.23764632101745,
      height: 0,
      seed: 980176757,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [9.23764632101745, 0],
      ],
    },
    {
      type: 'line',
      version: 261,
      versionNonce: 936271835,
      isDeleted: false,
      id: '4kzg7pp94Q5xMr6jA1lyq',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 615.378872328252,
      y: -226.01585656143268,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 2.2706732669124676,
      height: 0,
      seed: 52509781,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [2.2706732669124676, 0],
      ],
    },
    {
      type: 'line',
      version: 322,
      versionNonce: 984375125,
      isDeleted: false,
      id: 'Ht3mTSuq0oEeUFz97fqSE',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 602.9174900539347,
      y: -219.95719801611364,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 2.2706732669124676,
      height: 0,
      seed: 1649153371,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [2.2706732669124676, 0],
      ],
    },
    {
      type: 'line',
      version: 358,
      versionNonce: 1548463227,
      isDeleted: false,
      id: 'GnwdyqK2tSn6apae4twB_',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 614.8798197050568,
      y: -214.61964109985965,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 2.2706732669124676,
      height: 0,
      seed: 2088403029,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [2.2706732669124676, 0],
      ],
    },
    {
      type: 'line',
      version: 306,
      versionNonce: 552578229,
      isDeleted: false,
      id: 'raLFKjrKmRczLtkSSYkml',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 615.5109699778702,
      y: -203.75770156407893,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 2.2706732669124676,
      height: 0,
      seed: 1859524597,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [2.2706732669124676, 0],
      ],
    },
    {
      type: 'line',
      version: 394,
      versionNonce: 1756783899,
      isDeleted: false,
      id: 'jnUcl1HgSgsc0W1mCvRA3',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 603.353711358971,
      y: -209.309437255163,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffc9c9',
      width: 1.1353366334560633,
      height: 0,
      seed: 582988987,
      groupIds: ['GUC2YHdY4MyGjGqmuofzb'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [1.1353366334560633, 0],
      ],
    },
  ],
  files: {},
}
export default obj
