import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 461,
      versionNonce: 296873619,
      isDeleted: false,
      id: 'DLRTvymD-kFGJFxDXhfBY',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 770.1137341346873,
      y: 427.36619633324193,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 23.846987090565754,
      height: 44.573372354824805,
      seed: 908275131,
      groupIds: ['3un30lxSxZNBq0WewJmAC', 'oVQge2BCZ57llUJyK2zbs'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1712860397977,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.6520660532576201, 3.679515586239562],
        [-1.5370128398215552, 7.871368785752975],
        [-2.095926599756732, 12.01664583860514],
        [-2.3288073330629953, 17.140021971343774],
        [-4.564462372803753, 20.120895357664274],
        [-4.704190812787573, 23.846987090565097],
        [-3.0274495329816826, 28.271721023384515],
        [-6.008322919302298, 25.896337543660245],
        [-6.567236679237475, 22.123669664098163],
        [-5.589137599350995, 17.419478851310874],
        [-3.9123963195456177, 13.08789721181368],
        [-4.005548612868115, 9.08234859894528],
        [-5.822018332657567, 6.5672366792374515],
        [-7.498759612462891, 5.86859447931853],
        [-8.197401812381784, 6.474084385914955],
        [-8.104249519059287, 8.057673372397785],
        [-6.846693559205271, 10.898818318734683],
        [-8.29055410570433, 15.882466011489498],
        [-9.827566945525888, 21.56475590416326],
        [-9.920719238848434, 25.477152223709133],
        [-7.778216492429962, 29.715581569883575],
        [-4.238429346174172, 31.671779729656514],
        [0.7917944932419054, 31.99781275628534],
        [10.386480705461528, 31.99781275628534],
        [11.318003638686788, 29.85531000986737],
        [13.460506385104743, 25.570304517031424],
        [13.926267851717322, 21.657908197485547],
        [12.994744918492113, 18.630458664503646],
        [11.690612811976873, 16.441379771424405],
        [10.898818318735845, 9.967295385509605],
        [9.920719238849415, 4.704190812787187],
        [9.268653185591846, 0.46576146661286266],
        [10.47963299878454, -3.167177972965453],
        [10.526209145445865, -7.0795742925110705],
        [10.805666025413608, -9.78099079886397],
        [10.386480705462303, -12.342678865233255],
        [8.47685869235056, -11.178275198701728],
        [6.753541265883963, -9.315229332251336],
        [4.890495399433545, -9.408381625573861],
        [2.9342972396606335, -9.50153391889638],
        [0.7452183465814066, -11.411155932008073],
        [-1.1178275198691665, -12.575559598539465],
        [-1.5835889864817965, -10.898818318734088],
        [-1.3041321065142597, -7.964521079074695],
        [-1.3972843998367033, -4.517886226141451],
        [-0.6054899065953148, -2.0493504530946343],
        [0, 0],
      ],
    },
    {
      type: 'ellipse',
      version: 209,
      versionNonce: 1631364147,
      isDeleted: false,
      id: 'e8yrnNp1MoxsWBQj3Gb_s',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 770,
      y: 444.7838366788257,
      strokeColor: 'transparent',
      backgroundColor: 'transparent',
      width: 10,
      height: 10,
      seed: 915127899,
      groupIds: ['oVQge2BCZ57llUJyK2zbs'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712860397977,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
