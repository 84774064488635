import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

//@ts-ignore
const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 360,
      versionNonce: 1475586818,
      isDeleted: false,
      id: 'sDESsPiLCz0fFhudbynUq',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 900,
      y: 314,
      strokeColor: '#f08c00',
      backgroundColor: '#ffec99',
      width: 120,
      height: 40,
      seed: 552372923,
      groupIds: ['gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712753441109,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [120, 0],
        [100, 40],
        [20, 40],
        [0, 0],
      ],
    },
    {
      id: 't5foMVJLTQpXWXJpfmsMR',
      type: 'line',
      x: 900,
      y: 314,
      width: 120,
      height: 4,
      angle: 0,
      strokeColor: '#f08c00',
      backgroundColor: '#ffec99',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      seed: 1647023681,
      version: 320,
      versionNonce: 313654814,
      isDeleted: false,
      boundElements: null,
      updated: 1712753441109,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [4, -4],
        [116, -4],
        [120, 0],
        [0, 0],
      ],
      lastCommittedPoint: [120, 0],
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      id: 'm_DzcqPIfSnWxskB-O0qH',
      type: 'line',
      x: 994,
      y: 350,
      width: 108,
      height: 32,
      angle: 0,
      strokeColor: '#f08c00',
      backgroundColor: '#fff9db',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      seed: 1980318191,
      version: 346,
      versionNonce: 671808194,
      isDeleted: false,
      boundElements: null,
      updated: 1712753441109,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [-72, 0],
        [-88, -32],
        [20, -32],
        [4, 0],
      ],
      lastCommittedPoint: [0, 0],
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      id: 'CMHq_fuk0tDnq4JdjEC4v',
      type: 'line',
      x: 959.9791706140377,
      y: 319,
      width: 0,
      height: 30,
      angle: 0,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      groupIds: ['H9wPiBIFMC4Noj-zMHyX1', 'gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      seed: 135309519,
      version: 393,
      versionNonce: 1850247774,
      isDeleted: false,
      boundElements: null,
      updated: 1712753441109,
      link: null,
      locked: false,
      points: [
        [0, 0],
        [0, 30],
      ],
      lastCommittedPoint: null,
      startBinding: null,
      endBinding: null,
      startArrowhead: null,
      endArrowhead: null,
    },
    {
      type: 'line',
      version: 464,
      versionNonce: 1612862082,
      isDeleted: false,
      id: '3eFstAkrDJb9j4XB7cSbu',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 941.2009231855843,
      y: 319,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 5,
      height: 30,
      seed: 234024653,
      groupIds: ['H9wPiBIFMC4Noj-zMHyX1', 'gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712753441109,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [5, 30],
      ],
    },
    {
      type: 'line',
      version: 542,
      versionNonce: 1740869278,
      isDeleted: false,
      id: 'VKVpTCO12WbEmJN5_hEU3',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 922.449345489307,
      y: 318.87251628103763,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 11.119218510190194,
      height: 30.25496743792462,
      seed: 714221837,
      groupIds: ['H9wPiBIFMC4Noj-zMHyX1', 'gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712753441109,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [11.119218510190194, 30.25496743792462],
      ],
    },
    {
      type: 'line',
      version: 522,
      versionNonce: 318288450,
      isDeleted: false,
      id: '_QEX7p9UU_Ocnvni5olvz',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 978.9876423146161,
      y: 318.9999999999999,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 5.000000000000019,
      height: 30.000000000000114,
      seed: 1844330893,
      groupIds: ['H9wPiBIFMC4Noj-zMHyX1', 'gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712753441109,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-5.000000000000019, 30.000000000000114],
      ],
    },
    {
      type: 'line',
      version: 603,
      versionNonce: 1003752158,
      isDeleted: false,
      id: 'Hx4aD9Mu4H1iSMTD6Wxk-',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 997.7392200108934,
      y: 318.87251628103763,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 11.119218510190237,
      height: 30.254967437924734,
      seed: 1999144941,
      groupIds: ['H9wPiBIFMC4Noj-zMHyX1', 'gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712753441109,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-11.119218510190237, 30.254967437924734],
      ],
    },
    {
      type: 'line',
      version: 656,
      versionNonce: 594352642,
      isDeleted: false,
      id: 'A5j_VghpROJnWrg2NG8sI',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 914.8908521894857,
      y: 335,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 90.81638867924903,
      height: 0,
      seed: 1537909517,
      groupIds: ['lJtJ0XirEZSOr0OI5D4Zw', 'gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712753441109,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [90.81638867924903, 0],
      ],
    },
    {
      type: 'line',
      version: 769,
      versionNonce: 1762328350,
      isDeleted: false,
      id: 'cxjJ8L6SAawe_ewW146jz',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 910.3237291289397,
      y: 326.6263466643943,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 99.74797764529353,
      height: 0,
      seed: 1110846371,
      groupIds: ['6doGEimagteCD0_9gQ8Eu', 'gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712753441109,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [99.74797764529353, 0],
      ],
    },
    {
      type: 'line',
      version: 847,
      versionNonce: 1082034626,
      isDeleted: false,
      id: 'anTHRMxF5azYekpdIpOnE',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 919.5484610100013,
      y: 343.0757837905378,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 81.20366002640503,
      height: 0,
      seed: 851427011,
      groupIds: ['XPJmWAM6yRsIrFhrUX_id', 'gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712753441109,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [81.20366002640503, 0],
      ],
    },
    {
      type: 'line',
      version: 695,
      versionNonce: 1943628638,
      isDeleted: false,
      id: 'KFC8M2k7_oV7Sfsx9-wEz',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 926,
      y: 350,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 108,
      height: 32,
      seed: 1728380385,
      groupIds: ['gfshQQGV2Zn9JvtCb62fi'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712753441109,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [72, 0],
        [88, -32],
        [-20, -32],
        [-4, 0],
      ],
    },
  ],
  files: {},
}
export default obj
