import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 2783,
      versionNonce: 618187605,
      isDeleted: false,
      id: 'NoEE2RBL7LV17S2FZCdHS',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 595.4189402875578,
      y: -166.77770951620388,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 33.5355400364676,
      height: 21.944211147773974,
      seed: 258626902,
      groupIds: ['XO3UF6JZ3nwGtWS6shH0R'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [8.02220372375595, 0.027565512229982034],
        [10.38782400655905, -0.5716439397958945],
        [10.519781506579939, -3.4227040308489736],
        [10.244126384280186, -6.930467064660935],
        [8.983416847841502, -7.437241278475561],
        [5.3401887191526605, -7.422922062622729],
        [-13.870311785307457, -7.421849143147069],
        [-18.56460918269864, -7.167817262840997],
        [-19.68183772417962, -6.369634745861768],
        [-19.727302256225602, -2.9145680611294504],
        [-20.206510957810334, -1.7377723990714238],
        [-19.323413856082652, -1.0078949938220532],
        [-18.137096119825177, -0.46861370790950335],
        [-17.588003923284113, 1.6574383286548122],
        [-19.305370418052107, 5.437132262588869],
        [-22.4213462195154, 10.933264190029758],
        [-23.015758529887663, 12.954924238803097],
        [-22.236836788628995, 14.293460961170512],
        [-19.277877114929076, 14.506969869298413],
        [-13.805588109653776, 14.44303746840556],
        [-12.317866802170888, 13.9396986403395],
        [-12.246546323595156, 12.91743844741972],
        [-12.555601730756946, 12.27555414023753],
        [-12.460507759322532, 11.324614425893543],
        [-11.771076466423128, 10.80159758300436],
        [-11.509568044978522, 10.183486768680767],
        [-11.771076466423128, 9.280094040053989],
        [-11.39070058068547, 8.305380832851409],
        [-10.72504278064475, 7.948778439972411],
        [-10.249572923472723, 6.997838725628436],
        [-10.415987373482915, 5.904258054132851],
        [-9.441274166280673, 4.216340061172332],
        [-7.682035694744299, 4.596715946909922],
        [-2.3092263087008074, 4.8582243683545165],
        [-0.9065902300434086, 4.525395468334122],
        [-0.6450818085988026, 3.4080413039799446],
        [-0.2884794157198273, 0.07975230377601383],
      ],
    },
    {
      type: 'line',
      version: 333,
      versionNonce: 1248591995,
      isDeleted: false,
      id: 'PyV2Es5vdAjdauP73kdkM',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 576.3602102869563,
      y: -174.07185156394274,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 2.0017552201377518,
      height: 0.9237345938758448,
      seed: 76258698,
      groupIds: ['XO3UF6JZ3nwGtWS6shH0R'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0.2139937475695727, -0.9237345938758448],
        [2.0017552201377518, -0.836526717165189],
        [1.9885625554216722, -0.2839830153570541],
      ],
    },
    {
      type: 'line',
      version: 262,
      versionNonce: 1655609525,
      isDeleted: false,
      id: '5C4WTvA-FEmNROMDGmWlF',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 575.8408486985425,
      y: -169.70671318657648,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 30.15030430142972,
      height: 0,
      seed: 1174457622,
      groupIds: ['XO3UF6JZ3nwGtWS6shH0R'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [30.15030430142972, 0],
      ],
    },
    {
      type: 'line',
      version: 200,
      versionNonce: 1470904603,
      isDeleted: false,
      id: 'vvfusqbwDtVayOYedTWuN',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 597.4507234553612,
      y: -169.66357970402794,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 0,
      height: 2.8899433307522076,
      seed: 515042326,
      groupIds: ['XO3UF6JZ3nwGtWS6shH0R'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 2.8899433307522076],
      ],
    },
    {
      type: 'line',
      version: 548,
      versionNonce: 1931721237,
      isDeleted: false,
      id: 'Lq8_Xzt7MCmx5WsMR2ELu',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 593.4005156596251,
      y: -166.7222082952368,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffffff',
      width: 6.391664693352097,
      height: 3.7550411085294533,
      seed: 14035402,
      groupIds: ['XO3UF6JZ3nwGtWS6shH0R'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.33686931950116633, 3.6818916213578547],
        [-4.908883516637659, 3.701232343280433],
        [-6.391664693352097, 1.7431610390933696],
        [-5.743393714466023, 0.1432735100624732],
        [-3.1890798200125667, -0.053808765249020285],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 612,
      versionNonce: 228351419,
      isDeleted: false,
      id: 'ri-yGweKTgvFnpowvRoSD',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 589.6885493074358,
      y: -166.69425545958256,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 2.6832271980806937,
      height: 2.9037664198407964,
      seed: 180298890,
      groupIds: ['XO3UF6JZ3nwGtWS6shH0R'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 1.5805310892804505],
        [0.349187101120065, 2.8486316144008046],
        [-0.5881045913601687, 2.315661828480639],
        [-1.4886397468804002, 1.5070180153604238],
        [-2.3340400969606288, 0.3308088326400865],
        [-1.010804766400193, -0.05513480543999191],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 558,
      versionNonce: 1865265013,
      isDeleted: false,
      id: 'I05VN5gyKSLk-xfFIgYdZ',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 580.9280814536035,
      y: -154.5861318596593,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 9.547585826435375,
      height: 11.271455489541498,
      seed: 997167254,
      groupIds: ['XO3UF6JZ3nwGtWS6shH0R'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [3.182528608811747, -8.884559032932723],
        [1.8122732355733102, -11.138850130841007],
        [-0.7514303659694246, -10.829437627206547],
        [-1.54706251817235, -9.282375109034174],
        [-3.0499232501112443, -6.365057217623443],
        [-5.569425065420523, -2.740510746476755],
        [-6.365057217623629, -0.39781607610146275],
        [-3.491941112446343, 0.13260535870049134],
        [0, 0],
      ],
    },
  ],
  files: {},
}
export default obj
