import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 253,
      versionNonce: 115036149,
      isDeleted: false,
      id: 'i_YN_FYQDg_SPV32pfgvU',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1204.7210754966395,
      y: -396.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 169.99999999999997,
      height: 60,
      seed: 2112251899,
      groupIds: ['frBLKVDfFC4GW5zZEgw_1'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048713074,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 293,
      versionNonce: 1664718299,
      isDeleted: false,
      id: '-TSPEQ0Mn6wK-5tUSrLqB',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1194.7210754966395,
      y: -386.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 15.765365448229772,
      height: 43.64804817234466,
      seed: 242069659,
      groupIds: ['frBLKVDfFC4GW5zZEgw_1'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048713074,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 259,
      versionNonce: 926386517,
      isDeleted: false,
      id: 'GuDhksAj_b3CIpSZxJ5qu',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1366.7210754966395,
      y: -386.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 15.765365448229772,
      height: 43.64804817234466,
      seed: 1816323387,
      groupIds: ['frBLKVDfFC4GW5zZEgw_1'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048713074,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 307,
      versionNonce: 551559803,
      isDeleted: false,
      id: 'A4NZg_WxPDKqa-Llwwxmb',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1198.7210754966395,
      y: -396.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 180.00000000000003,
      height: 20,
      seed: 248022491,
      groupIds: ['frBLKVDfFC4GW5zZEgw_1'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048713074,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
