import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'ellipse',
      version: 627,
      versionNonce: 1540097979,
      isDeleted: false,
      id: '5JOCFh8dGg9LLODRcf7nL',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 629.4130244929859,
      y: -175.58050463990438,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 23.055563699718324,
      height: 22.45782686305893,
      seed: 1615660156,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 813,
      versionNonce: 480617845,
      isDeleted: false,
      id: '3Carueo_LIjARlmxtdHPJ',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 632.6017219005939,
      y: -172.78188734398182,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 16.800736647555247,
      height: 16.77219976096865,
      seed: 1637366340,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 1322,
      versionNonce: 638146651,
      isDeleted: false,
      id: '0SvrYjywIi1fVvXBZ6Sts',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 639.0828930404507,
      y: -163.95536556553262,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 19.795761334513667,
      height: 22.886991332695985,
      seed: 1683015804,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 0],
        [-5.766332881224594, 2.55620942157384],
        [-12.959387300072022, 5.94467307342753],
        [-14.088875184023237, 8.32254230279854],
        [-16.64508460559698, 12.781047107869188],
        [-16.763978067065608, 14.386108837694618],
        [-18.78516691203088, 18.190699604688245],
        [-19.320187488639455, 22.58975767902461],
        [-14.802235952834502, 20.03354825745077],
        [-13.969981722554607, 18.012359412485413],
        [-9.333136725281127, 13.316067684477675],
        [-7.430841341784283, 12.602706915666364],
        [-6.420246919301697, 7.846968456924337],
        [-4.399058074336326, 5.94467307342753],
        [-2.31842249863669, 3.685697305525066],
        [0.475573845874211, -0.29723365367137555],
      ],
    },
    {
      type: 'line',
      version: 831,
      versionNonce: 471543509,
      isDeleted: false,
      id: 'tIcInQDpZNcT7XS9Nbbdy',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 639.4990201555904,
      y: -162.7069842201128,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 10.462624609232137,
      height: 23.83813902444444,
      seed: 1131150532,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.951147691748422, 4.16127115139927],
        [-0.5350205766084747, 7.668628264721513],
        [4.8151851894762725, 16.64508460559708],
        [9.511476917483714, 23.83813902444444],
        [7.490288072518444, 17.417892105142705],
        [7.311947880315552, 9.987050763358292],
        [6.420246919301495, 5.766332881224745],
        [2.734549613776335, 3.2101234596509114],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 785,
      versionNonce: 697537787,
      isDeleted: false,
      id: 'mqSqVhubqSZ6dCeTfy0qR',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 633.435453620694,
      y: -149.0342361512294,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 6.2419067270988045,
      height: 15.158916337240198,
      seed: 2069510724,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, -3.3884636518536904],
        [2.02118884496527, -9.035903071609845],
        [3.507357113322167, -11.65155922391796],
        [6.2419067270988045, -15.158916337240198],
        [5.944673073427485, -12.89994056933774],
        [4.8151851894762725, -7.787521726190066],
        [3.6262505747907956, -3.626250574790796],
        [2.02118884496527, -1.486168268356884],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 820,
      versionNonce: 450399285,
      isDeleted: false,
      id: 'JnGTsXLavbyemF4OZsWcG',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 633.0815963872353,
      y: -177.21599019396504,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 7.747614357647656,
      height: 14.540043383530413,
      seed: 910488260,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [4.66979495529453, 4.722860807059145],
        [6.686297322353453, 7.004692432941656],
        [7.747614357647656, 9.60491916941242],
        [7.588416802353485, 13.001133682353837],
        [6.5270997670592825, 14.540043383530413],
        [3.7146096235297077, 11.62142153647138],
        [3.926873030588568, 8.808931392941782],
        [2.706358440000195, 5.943375397647467],
        [2.334897477647265, 3.714609623529657],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 762,
      versionNonce: 99433883,
      isDeleted: false,
      id: '7SyXCylJTbiundgh3K9Jg',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 637.33563786217,
      y: -167.61107102455256,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 17.989323748236544,
      height: 7.482285098824056,
      seed: 2116244348,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-4.510597400000258, -2.3348974776472144],
        [-10.7193020564713, -3.2900828094119863],
        [-16.55654575058921, -3.5023462164707713],
        [-13.478726348236087, -1.1143828870588417],
        [-7.959877764706314, 1.379712145882512],
        [-4.669794955294328, 1.751173108235474],
        [1.432777997647334, 3.9799388823532835],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 887,
      versionNonce: 147013013,
      isDeleted: false,
      id: 'MIrzjJzirxUJnU24ERbVr',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 640.6700131895891,
      y: -164.957778436317,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 29.769942840002045,
      height: 16.503479898824665,
      seed: 1633442940,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [6.898560729412313, 2.2818316258825053],
        [13.690989755295144, 5.359651028235662],
        [19.528233449413158, 8.596667985882942],
        [23.29590892470755, 11.51528983294197],
        [26.479860030590057, 15.017636049412799],
        [28.81475750823732, 16.503479898824665],
        [23.720435738825174, 15.176833604706925],
        [13.425660496471593, 12.523541016471452],
        [6.474033915294592, 9.23345820705946],
        [1.9634365152943343, 5.890309545882758],
        [-0.9551853317647213, 2.2287657741178033],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 964,
      versionNonce: 1179177531,
      isDeleted: false,
      id: 'aQRhiHY8Q3801R3ER2dm8',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 663.9128562625319,
      y: -188.14755565749513,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 23.136711369413383,
      height: 23.720435738825223,
      seed: 1191265532,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-5.04125591764736, 1.8042389600001385],
        [-10.719302056471303, 6.261770508235732],
        [-12.948067830589086, 6.580165618823998],
        [-18.148521303530618, 13.2664629411774],
        [-20.48341878117788, 18.785311524707186],
        [-22.765250407060357, 19.899694411766085],
        [-23.136711369413383, 23.720435738825223],
        [-18.042389600001336, 21.810065075295675],
        [-11.992882498824365, 17.35253352706008],
        [-5.837243694118112, 11.51528983294203],
        [-2.918621847059056, 7.110824136471124],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 929,
      versionNonce: 1024508661,
      isDeleted: false,
      id: 'cwW0soarHfYZNcJWS3_qs',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 668.3703878107667,
      y: -161.45543221984613,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 30.512864764708013,
      height: 6.792429025882821,
      seed: 1512468732,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-6.314836360000422, -1.9634365152942523],
        [-11.143828870589022, -2.9716876988237333],
        [-17.352533527059965, -3.3962145129414103],
        [-23.24284307294276, -3.3962145129414103],
        [-25.949201512942963, -3.130885254117866],
        [-12.895001978824395, 2.3879633294119293],
        [-6.739363174118043, 3.1839511058825685],
        [-0.530658517647101, 3.3962145129414103],
        [4.563663251765049, 2.9716876988237333],
        [1.591975552941303, 1.8573048117648285],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 998,
      versionNonce: 1804128987,
      isDeleted: false,
      id: 'MzmtuE7QiqLqZ7mWJu0j4',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 640.9276601665943,
      y: -163.26773915771017,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 19.368811905664906,
      height: 10.258481834143318,
      seed: 1824020164,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [4.005628288039474, 3.4783796649277043],
        [8.396080267465297, 6.289401368567569],
        [15.047848174988173, 8.909794786682635],
        [19.368811905664906, 10.258481834143318],
        [11.236276474960007, 7.403698374721112],
        [6.468314138513249, 4.66601053490566],
        [2.7118422374002544, 2.085930861684659],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1127,
      versionNonce: 205954133,
      isDeleted: false,
      id: 'SGPqK0rHBrTmCf0zJLA8-',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 644.8711475241603,
      y: -164.18954079190306,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 18.47972495497932,
      height: 3.438097863200377,
      seed: 1986973180,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [4.830697830307409, 1.4487116591048768],
        [11.591817160394813, 2.70383157107868],
        [18.47972495497932, 3.438097863200377],
        [11.815184446620632, 2.7902119100624003],
        [4.640762928309476, 1.3083117355182037],
        [2.5781143293243614, 0.6254851830270907],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 922,
      versionNonce: 910147451,
      isDeleted: false,
      id: 'c9lt42b24xZtsGIB1qZs4',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 644.5678507521834,
      y: -149.00318225475579,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 5.691542122178217,
      height: 13.494690945857826,
      seed: 1337452796,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-1.7443522357293078, -3.6633109064279377],
        [-3.128350237990694, -6.7392087791585435],
        [-3.615187581345039, -9.451060904417695],
        [-4.516716054783458, -11.38979424177566],
        [-2.4475543070722465, -7.783222696489875],
        [0.09790217228293013, -1.174826067394696],
        [1.174826067394759, 2.1048967040821673],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 804,
      versionNonce: 507136437,
      isDeleted: false,
      id: 'oNAxkzNeGKk9la4jIoKUk',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 641.1537303445889,
      y: -165.2477943202156,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 16.990741348794245,
      height: 15.993039065729057,
      seed: 874560580,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [1.9201945876077173, -1.3871137423568607],
        [8.120945851138364, -7.939107015130086],
        [16.990741348794245, -15.993039065729057],
        [10.703629272787513, -10.419020936586472],
        [5.355890292578285, -5.2285683969716095],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 734,
      versionNonce: 816846875,
      isDeleted: false,
      id: 'MxXxAlVvsQasNBrRBcZWj',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 639.2718036653375,
      y: -164.75940317478992,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.6278315192834314,
      height: 7.604396829529921,
      seed: 1127467204,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.5044645712591719, -1.4702256463487218],
        [-1.526826435122622, -4.615954458236513],
        [-2.6278315192834314, -7.604396829529921],
        [-0.268534910367498, -2.571230730509449],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 806,
      versionNonce: 68242197,
      isDeleted: false,
      id: 'vyIsVeXoOob52LTI3t71g',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 637.7315445088691,
      y: -164.31222698901823,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 12.884055407394555,
      height: 5.22041708460265,
      seed: 293880772,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-6.04209524153863, -3.4902662380643665],
        [-12.884055407394555, -5.22041708460265],
        [-8.715964731643293, -4.984487423711064],
        [-3.6827986326227964, -3.0970501365783907],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 779,
      versionNonce: 2026659003,
      isDeleted: false,
      id: 'gMZ5K3mgvqL3dMzqvlKCc',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 638.290903065368,
      y: -161.25320055028706,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 3.4930027784613142,
      height: 8.314275959374418,
      seed: 1418693500,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-1.4064245514948595, 3.6911655757865187],
        [-3.4557249861492436, 8.314275959374418],
        [-2.118790241096962, 5.5617632489726025],
        [-0.6245690554502649, 1.786888674707255],
        [0.037277792312070075, 0.3727779231205071],
      ],
    },
    {
      type: 'line',
      version: 978,
      versionNonce: 239149173,
      isDeleted: false,
      id: 'jakxpsIXWGX8d-Z1L4Oja',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 636.4470440671387,
      y: -161.6410234866668,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 14.782561569863336,
      height: 17.116923467553956,
      seed: 515330300,
      groupIds: ['Xbmv1bQUFF1u-4IKnLK9S'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725567,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-7.075525980738275, 4.219435338814009],
        [-12.428090216926563, 12.096300216691713],
        [-14.782561569863336, 17.116923467553956],
        [-12.344621740650346, 11.61189804675032],
        [-6.367736998063556, 4.92722432148876],
        [0, 0],
      ],
    },
  ],
  files: {},
}
export default obj
