import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 186,
      versionNonce: 1881296405,
      isDeleted: false,
      id: 'l5eyw6pnGzFDLjXfAFBBM',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 642.1326465865126,
      y: -213.90860039380763,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 37.45098820383328,
      height: 13.979688613358068,
      seed: 1683860602,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 273,
      versionNonce: 1417742779,
      isDeleted: false,
      id: 'ppN7XyD2MKwRvTtj9CpM2',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 649.3374826420334,
      y: -203.66332678998396,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 23.04131609279174,
      height: 1.3498657698422547,
      seed: 1828101862,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 329,
      versionNonce: 2000274293,
      isDeleted: false,
      id: 'QlRGyFnSLF0ZjM9kpS_PH',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 644.9595564601619,
      y: -207.51962122683597,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 3.603245801965547,
      height: 1.2590336656795484,
      seed: 119383674,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 431,
      versionNonce: 80616027,
      isDeleted: false,
      id: 'rWeUpXDD3OmA2L6ybu31v',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 644.9595564601619,
      y: -203.63333474098738,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 1168199206,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 475,
      versionNonce: 175869141,
      isDeleted: false,
      id: 'mIWtK1X1Xq6ZP1sQfkDwp',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 674.3891582088895,
      y: -203.663326789984,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 1573930534,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 360,
      versionNonce: 376444667,
      isDeleted: false,
      id: 'XhenFR5dlCiNjTOiuoCWq',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 672.9358445422857,
      y: -207.51962122683597,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 3.603245801965547,
      height: 1.2590336656795484,
      seed: 387062630,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 455,
      versionNonce: 1501375029,
      isDeleted: false,
      id: 'DevJ_-AoU7UDa6jWjVVil',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 659.6923425165855,
      y: -207.47420517475464,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 562185594,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 519,
      versionNonce: 1743355803,
      isDeleted: false,
      id: 'b1o_QQi8XAmwxIOFxqoYF',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 650.6819790224147,
      y: -207.47420517475464,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 767186214,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 474,
      versionNonce: 86252437,
      isDeleted: false,
      id: 'UrkfWupqoSl09QycoUGci',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 655.2235842305514,
      y: -207.47420517475462,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 106408038,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 457,
      versionNonce: 1668076603,
      isDeleted: false,
      id: 'xex8--PMJn4S5yLKtjA2E',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 664.3067946468258,
      y: -207.47420517475464,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 930736250,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 478,
      versionNonce: 1973805301,
      isDeleted: false,
      id: 'WVPqAR0XzC_QuVVCeGSBr',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 668.8483998549625,
      y: -207.56503727891734,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 1390514682,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 500,
      versionNonce: 148673755,
      isDeleted: false,
      id: 'Pvv3wJge2O4oFd_El7lsV',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 659.7831746207482,
      y: -211.18696743240665,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 1807961658,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 564,
      versionNonce: 1887959637,
      isDeleted: false,
      id: 'fWFWKdZlaf-mGC7er4bOH',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 650.7728111265774,
      y: -211.18696743240665,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 1769043706,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 519,
      versionNonce: 936067451,
      isDeleted: false,
      id: 'WYMkPc84fKVDOQsP5yW8B',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 655.3144163347142,
      y: -211.1869674324066,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 148670394,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 502,
      versionNonce: 1855973301,
      isDeleted: false,
      id: 'xD3o0lHId1oJZAJW1DnTB',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 664.3976267509887,
      y: -211.18696743240665,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 1378320506,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 523,
      versionNonce: 964385307,
      isDeleted: false,
      id: 'yQvI4nkCj34O5E8RDihR0',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 668.9392319591253,
      y: -211.27779953656932,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 1360355642,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 554,
      versionNonce: 840116501,
      isDeleted: false,
      id: 'Fr_mw5PZwWSlAE9NYSGoz',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 673.3900050630994,
      y: -211.23238348448797,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 698979558,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 589,
      versionNonce: 182410939,
      isDeleted: false,
      id: '-Pjg6CwJ4kFIox4eRumZH',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 646.2312059184401,
      y: -211.23238348448794,
      strokeColor: '#ffa1a1',
      backgroundColor: '#ffa1a1',
      width: 2.331596343687205,
      height: 1.3498657698423029,
      seed: 1897783418,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 165,
      versionNonce: 475479669,
      isDeleted: false,
      id: 'QqRAwTPlSCfe4e64DS7BT',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 659.6754494023133,
      y: -217.2390120090187,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 2.365382572231804,
      height: 2.6573657914615634,
      seed: 786011430,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 1658,
      versionNonce: 1721356123,
      isDeleted: false,
      id: '42S-Q_H-BxEUsxrTaDzZo',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 660.9403605346582,
      y: -217.774933097546,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 17.46122074361743,
      height: 9.643589700427073,
      seed: 1805586790,
      groupIds: ['RgzDq0q4uJZSeMOG8NiDu'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725565,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0.5451555619798998, -6.19797743922474],
        [3.9764896213106913, -8.331939943833483],
        [7.571820379520759, -6.607754718279698],
        [10.281991959524097, -0.4973768687140614],
        [13.571950423375636, 0.790887494457949],
        [16.025487988969928, -1.0950031356969432],
        [17.46122074361743, -8.852702205969125],
      ],
    },
  ],
  files: {},
}
export default obj
