import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

//@ts-ignore
const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 86,
      versionNonce: 134632051,
      isDeleted: false,
      id: '2B1HgeHYrMlxYw8I8CFcC',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1240,
      y: 445,
      strokeColor: '#f08c00',
      backgroundColor: '#ffec99',
      width: 60,
      height: 5,
      seed: 1334273730,
      groupIds: ['oWu6OSFgu2_ISBhzsXZ34'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857779106,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [60, 0],
        [55, -5],
        [5, -5],
      ],
    },
    {
      type: 'line',
      version: 114,
      versionNonce: 538214973,
      isDeleted: false,
      id: '-cGvzZEv4SzJVfAjRPqix',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1240.0614011974799,
      y: 445,
      strokeColor: '#f08c00',
      backgroundColor: '#ffec99',
      width: 60,
      height: 20,
      seed: 892419714,
      groupIds: ['oWu6OSFgu2_ISBhzsXZ34'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857779106,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [10, 20],
        [50, 20],
        [60, 0],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 405,
      versionNonce: 2065271827,
      isDeleted: false,
      id: 'c6wT22EKC97BCTeHZgOdq',
      fillStyle: 'solid',
      strokeWidth: 4,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1288.844657570109,
      y: 459.2973074874882,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 36.848442036439565,
      height: 0,
      seed: 1061822018,
      groupIds: ['uP1wbWZiSk58nF5EqA3aL', 'oWu6OSFgu2_ISBhzsXZ34'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857779106,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-36.848442036439565, 0],
      ],
    },
    {
      type: 'line',
      version: 426,
      versionNonce: 102970013,
      isDeleted: false,
      id: 'ZxyT3U4BtJre9lCpiYzFS',
      fillStyle: 'solid',
      strokeWidth: 4,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1292.4705423068551,
      y: 450.48362359323653,
      strokeColor: '#fff9db',
      backgroundColor: '#fff9db',
      width: 44.474326773185794,
      height: 0,
      seed: 447809026,
      groupIds: ['uP1wbWZiSk58nF5EqA3aL', 'oWu6OSFgu2_ISBhzsXZ34'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857779106,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-44.474326773185794, 0],
      ],
    },
  ],
  files: {},
}
export default obj
