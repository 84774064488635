import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 7376,
      versionNonce: 2076255413,
      isDeleted: false,
      id: 'pTl5_QX7FIEy0yWWXbQX5',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1731.4306290582872,
      y: -511.3524330618301,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 214.1353276575419,
      height: 187.49150733587965,
      seed: 2012439932,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-7.3434668800357485, -7.749416349519606],
        [-4.988456987774953, -13.498468829419949],
        [1.9505531245283692, -10.284734656603973],
        [1.1899576324030932, -16.94530756565348],
        [10.38584884853708, -14.004039789085368],
        [15.072455962264485, -8.866150566037845],
        [16.795875330381477, -14.842336802349905],
        [23.22931448301938, -11.525995735849278],
        [22.19177448939149, -18.161663272353394],
        [29.258296867925083, -17.880240665596602],
        [32.982080105661, -14.185840905660598],
        [31.386173003774275, -27.485066754717423],
        [40.15120937787901, -26.923714242955384],
        [45.164571738199584, -18.264270166038045],
        [48.38278401627008, -24.49398823305853],
        [53.57346938791147, -23.90922305050242],
        [54.97013350943507, -17.732301132075804],
        [58.74223479051034, -25.609229747825452],
        [65.22548468823925, -24.010337242435526],
        [67.10430709863431, -19.13045795535095],
        [74.18567691097564, -24.861821019920285],
        [77.78071818478884, -16.291627210159845],
        [77.19473221280714, -10.527048437206076],
        [83.66592838448469, -26.13356983253732],
        [88.0090930418412, -21.13776586985864],
        [87.35665686787615, -5.7307982118342125],
        [94.09385697884386, -13.35740455981886],
        [100.36140975413969, -7.089851784523034],
        [99.85053614347021, -12.359020098693009],
        [107.07334213026843, -12.350034421688179],
        [107.35214169581559, -4.920314285382119],
        [110.9446747675047, -8.016350829609223],
        [115.00674733563983, -6.800269406569441],
        [112.65545558260465, -1.3044184534807073],
        [118.13333518168338, -5.832436247260489],
        [121.23116886131174, -4.409440674712755],
        [119.16406808002739, -0.3401795649735959],
        [122.9293697286048, 4.056403766139738],
        [121.09254585704139, 13.882344040505473],
        [126.36890271281527, 11.502298121054594],
        [129.04751668722474, 15.328702373266026],
        [132.15074177305542, 8.836264094452304],
        [137.18963317750513, 11.745154978582377],
        [135.31506946252057, 19.90883709367455],
        [140.57704923508618, 16.555566608711672],
        [145.35690956339886, 19.207223552106086],
        [141.5826222378164, 25.21215098046332],
        [146.4027027227703, 24.971648237127283],
        [149.42670397170878, 28.178051071790378],
        [145.43957779184439, 33.40818153277331],
        [151.4940074662386, 35.190849436966346],
        [145.6806375139713, 39.91679403019589],
        [154.2775059718415, 39.40562400713634],
        [154.88336886541583, 42.52431812659057],
        [147.6091152909853, 48.11282458250588],
        [155.82554133042277, 49.85135963969918],
        [150.50183195650652, 55.34461624630876],
        [156.1403414571464, 55.84880211406255],
        [157.60066810051967, 60.66999655659788],
        [153.63560834415466, 62.33534818798529],
        [161.82298380478596, 62.26216476217945],
        [161.5523165250629, 66.09649691391706],
        [155.32302639904174, 66.19230374201351],
        [162.7958777849717, 72.21879679518258],
        [158.4852963867047, 74.32213502382376],
        [154.11772778840805, 74.1472745721967],
        [161.59057917433756, 82.10224540237994],
        [157.0679886327507, 83.96452390889431],
        [151.22501112288683, 82.58436484663343],
        [155.2562701497518, 88.0549980509524],
        [147.85017501311222, 87.64661901129546],
        [151.76356078838035, 92.56893350086125],
        [141.100502793563, 89.09297734405607],
        [150.5292115989405, 96.84935036274015],
        [150.32418661092424, 104.50404635560312],
        [143.09295192591253, 103.07919746755175],
        [135.3150694625192, 98.01218706274642],
        [140.033472921652, 108.4794321400185],
        [133.8687111297586, 106.69033705930991],
        [139.59492934560785, 115.49223050519447],
        [136.41702011095958, 117.72685331725359],
        [132.28297010069218, 116.62378871123173],
        [129.52963613147676, 113.19894955673254],
        [132.71397254264002, 123.0743000510422],
        [124.94950141106847, 118.98438288777487],
        [129.77013887481507, 127.57426719033936],
        [121.8157250234226, 124.52875649669096],
        [125.56988959404816, 130.16028184202474],
        [123.72157939814588, 134.4186322804037],
        [118.44088891364754, 129.10889121709948],
        [123.59029481472817, 138.4800558436558],
        [121.41321618149004, 141.10908938475956],
        [117.57134289565556, 141.3280826833864],
        [115.06605280387294, 134.8943245481418],
        [114.72275907713447, 144.11213816778024],
        [108.0753208621968, 138.26916065791653],
        [108.87866760968927, 144.41185602630986],
        [105.6647236409292, 143.33141482257855],
        [102.10692896664, 147.5090407010548],
        [98.52064069233529, 145.17972501848067],
        [94.99943773094878, 148.03473601351743],
        [91.20114031332355, 143.5724745447053],
        [89.6815985547571, 149.71516991309863],
        [87.10312503716841, 154.0316215090898],
        [85.17464726015442, 139.9565787128039],
        [83.55287149697574, 154.0316215090898],
        [78.14732360557537, 154.41793412524675],
        [76.25543754146406, 141.6439967676912],
        [74.58254477597961, 156.53691160087243],
        [68.7831431343252, 155.97406759671622],
        [68.78258615553432, 142.849295378325],
        [64.41446057844655, 155.32462883493247],
        [58.29327465476263, 155.9084253050072],
        [58.899137548337194, 143.0903551004518],
        [54.39972738783149, 156.10535218013422],
        [50.112326370646315, 160.00644058116222],
        [47.1087206088348, 159.60727361197428],
        [43.36908132761255, 157.5022644469609],
        [39.241458493860364, 148.60512115177056],
        [34.80014949096676, 159.43074222397507],
        [27.297690547440425, 154.07742529246173],
        [22.66903231562378, 143.68483722475742],
        [20.52261238813776, 153.09951131703315],
        [11.055892789476275, 151.66439859207128],
        [10.937455991248726, 138.84998913702714],
        [-0.5593615503767069, 147.65033069755145],
        [-7.130154179179499, 140.4334956830898],
        [-1.5920282428323844, 130.70878006072508],
        [-14.4588702217236, 136.04935933372832],
        [-19.926166884566555, 132.88900916365117],
        [-15.49134489283756, 125.04590684415552],
        [-23.588169266148725, 126.39305899791731],
        [-19.728066217209516, 114.88413312861695],
        [-28.80618842467902, 123.91422599404302],
        [-31.891935470283215, 117.6583501864842],
        [-27.35655833521878, 106.36278636162069],
        [-35.582083649228935, 108.66760110267506],
        [-31.395584087973475, 97.43747835963666],
        [-41.44990298207813, 103.67143139947609],
        [-44.301924974080976, 98.87973171864098],
        [-32.066868669016685, 89.12546207006329],
        [-41.427503158441596, 86.39851406292473],
        [-35.01857485793835, 80.77685608072733],
        [-43.73231789949591, 74.04037708253435],
        [-37.21760711877937, 65.8494481587494],
        [-49.164810744904344, 63.50493539894484],
        [-46.972873422125986, 54.751494338637144],
        [-51.339449872570185, 51.71030720984601],
        [-42.127958218639606, 41.51113150638412],
        [-43.80890609341873, 34.79759485483635],
        [-42.03100753599142, 28.153510682172055],
        [-31.88707494489927, 27.051476029544915],
        [-27.816643124614075, 17.489299675155337],
        [-18.94638779959473, 14.77911707024532],
        [-18.630292294621086, 6.823943108705066],
        [-10.346753850882578, 2.7592544992202193],
        [-8.090114723026772, -2.3320335416800617],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 583,
      versionNonce: 1644480021,
      isDeleted: false,
      id: 'IIuij6SUHaWiSWi_dmm0E',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1770.4438935772432,
      y: -489.10683324163506,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 12.336376087944927,
      height: 9.132122558608614,
      seed: 110943940,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-3.8451042352035074, -3.3644662058031827],
        [-0.6408507058672512, -6.568719735139496],
        [3.044040852869557, -4.165529588137247],
        [5.126805646938237, -7.049357764539991],
        [8.49127185274142, -4.165529588137247],
        [7.369783117473617, 2.0827647940686234],
      ],
    },
    {
      type: 'line',
      version: 719,
      versionNonce: 1980578677,
      isDeleted: false,
      id: 'gtGQJU_dfwAaYe7pPepmY',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1829.722583869966,
      y: -492.47129944743824,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 21.308285970086445,
      height: 10.413823970343117,
      seed: 2116915452,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-3.524678882270109, -6.889145088073121],
        [0.9612760588008769, -7.36978311747356],
        [3.6848915587368083, -3.6848915587368083],
        [5.607443676339017, -5.607443676338562],
        [8.49127185274142, -3.3644662058031827],
        [11.375100029143823, -6.24829438220587],
        [17.783607087816335, -3.845104235203621],
        [7.369783117473389, 3.044040852869557],
      ],
    },
    {
      type: 'line',
      version: 754,
      versionNonce: 1344275669,
      isDeleted: false,
      id: 'iFPDdsYO2gZhqNHq4QfbB',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1822.195863785128,
      y: -462.5068933932014,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 13.506963788026724,
      height: 12.456422160069621,
      seed: 1747798724,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [3.451779634717923, -5.552862890633435],
        [7.954100897393573, -4.8024760135207885],
        [4.6523986380980205, 0],
        [10.955648405843931, -3.151624883873012],
        [11.856112658379061, 0],
        [7.804023521970976, 2.4012380067604226],
        [13.506963788026724, 3.4517796347180933],
        [13.206809037181984, 6.303249767746024],
        [9.755029402463833, 6.903559269436187],
        [5.1026307643655855, 6.003095016901057],
      ],
    },
    {
      type: 'line',
      version: 859,
      versionNonce: 1674748469,
      isDeleted: false,
      id: 'APKYRjLnMi0Bw10gQgeEr',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 2.53158871073577,
      x: 1792.9795473820113,
      y: -384.11306415200823,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 21.308285970086445,
      height: 10.413823970343117,
      seed: 2116247108,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-3.524678882270109, -6.889145088073121],
        [0.9612760588008769, -7.36978311747356],
        [3.6848915587368083, -3.6848915587368083],
        [5.607443676339017, -5.607443676338562],
        [8.49127185274142, -3.3644662058031827],
        [11.375100029143823, -6.24829438220587],
        [17.783607087816335, -3.845104235203621],
        [7.369783117473389, 3.044040852869557],
      ],
    },
    {
      type: 'line',
      version: 760,
      versionNonce: 369791893,
      isDeleted: false,
      id: 'lmzlW7dtgY8rO_RTgM5Io',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 2.1995926132103403,
      x: 1855.957480850082,
      y: -431.33681685472476,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 12.336376087944927,
      height: 9.132122558608614,
      seed: 1855552068,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-3.8451042352035074, -3.3644662058031827],
        [-0.6408507058672512, -6.568719735139496],
        [3.7154699533198254, -3.2423145750173035],
        [5.126805646938237, -7.049357764539991],
        [8.49127185274142, -4.165529588137247],
        [7.369783117473617, 2.0827647940686234],
      ],
    },
    {
      type: 'line',
      version: 847,
      versionNonce: 318593269,
      isDeleted: false,
      id: 'VWmryAxy0LVP-zd77zf5a',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.2234103638765585,
      x: 1825.39640457542,
      y: -397.5456402714789,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 13.506963788026724,
      height: 12.456422160069621,
      seed: 1024764540,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [3.451779634717923, -5.552862890633435],
        [7.954100897393573, -4.8024760135207885],
        [4.6523986380980205, 0],
        [10.955648405843931, -3.151624883873012],
        [11.856112658379061, 0],
        [7.804023521970976, 2.4012380067604226],
        [13.506963788026724, 3.4517796347180933],
        [13.206809037181984, 6.303249767746024],
        [9.755029402463833, 6.903559269436187],
        [5.1026307643655855, 6.003095016901057],
      ],
    },
    {
      type: 'line',
      version: 895,
      versionNonce: 1680896597,
      isDeleted: false,
      id: '_3O4ZlyN4fs51pX2uuJv3',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1720.2124338179465,
      y: -429.25750818966856,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 12.722903642720894,
      height: 18.344651763923082,
      seed: 1810338556,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [1.0355851802214602, 7.101155521518592],
        [-2.9588148006328083, 10.059970322151344],
        [-6.50939256139236, 9.616148102056457],
        [-4.586162940981012, 5.621748121202188],
        [-8.136740701740337, 4.142340720885841],
        [-9.024385141929997, 1.1835259202530892],
        [-6.213511081328988, -1.183525920253146],
        [-11.39143698243629, -2.2191111004745494],
        [-11.687318462499434, -6.657333301423762],
        [-7.397037001582248, -8.284681441771738],
        [-2.367051840506292, -6.95321478148702],
      ],
    },
    {
      type: 'line',
      version: 1022,
      versionNonce: 1182359477,
      isDeleted: false,
      id: 'C8pRVvRCVp1mpKDYnwbiy',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.325817663668028,
      x: 1739.280934622686,
      y: -494.0450518713477,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 12.722903642720894,
      height: 18.344651763923082,
      seed: 1563318908,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [1.0355851802214602, 7.101155521518592],
        [-2.9588148006328083, 10.059970322151344],
        [-6.50939256139236, 9.616148102056457],
        [-4.586162940981012, 5.621748121202188],
        [-8.136740701740337, 4.142340720885841],
        [-9.024385141929997, 1.1835259202530892],
        [-6.213511081328988, -1.183525920253146],
        [-11.39143698243629, -2.2191111004745494],
        [-11.687318462499434, -6.657333301423762],
        [-7.397037001582248, -8.284681441771738],
        [-2.367051840506292, -6.95321478148702],
      ],
    },
    {
      type: 'line',
      version: 667,
      versionNonce: 1073586453,
      isDeleted: false,
      id: 'rLhsWXmnakcJp9HT4ornD',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 3.3123278650650967,
      x: 1755.4598496479284,
      y: -391.15386942206743,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 12.336376087944927,
      height: 9.132122558608614,
      seed: 834173508,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-3.8451042352035074, -3.3644662058031827],
        [-0.6408507058672512, -6.568719735139496],
        [3.044040852869557, -4.165529588137247],
        [5.126805646938237, -7.049357764539991],
        [8.49127185274142, -4.165529588137247],
        [7.369783117473617, 2.0827647940686234],
      ],
    },
    {
      type: 'line',
      version: 648,
      versionNonce: 1983034997,
      isDeleted: false,
      id: '3udNTl2VKjzurXc3NYTmv',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 5.0611599839685955,
      x: 1709.5821899512648,
      y: -466.52431035229995,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 12.336376087944927,
      height: 9.132122558608614,
      seed: 1045053820,
      groupIds: ['FJAfAwJv5_rEBQj5BI9rb', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-3.8451042352035074, -3.3644662058031827],
        [-0.6408507058672512, -6.568719735139496],
        [3.044040852869557, -4.165529588137247],
        [5.126805646938237, -7.049357764539991],
        [8.49127185274142, -4.165529588137247],
        [7.369783117473617, 2.0827647940686234],
      ],
    },
    {
      type: 'line',
      version: 951,
      versionNonce: 1138630613,
      isDeleted: false,
      id: 'K_T3CycvlCngJZNNzbKHC',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 3.9107779813041645,
      x: 1747.882406144272,
      y: -422.5434510940246,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 21.308285970086445,
      height: 10.413823970343117,
      seed: 1293270596,
      groupIds: ['Me29598k6kvtnWbHQDMJT', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-3.524678882270109, -6.889145088073121],
        [0.9612760588008769, -7.36978311747356],
        [3.6848915587368083, -3.6848915587368083],
        [5.607443676339017, -5.607443676338562],
        [8.49127185274142, -3.3644662058031827],
        [11.375100029143823, -6.24829438220587],
        [17.783607087816335, -3.845104235203621],
        [7.369783117473389, 3.044040852869557],
      ],
    },
    {
      type: 'line',
      version: 884,
      versionNonce: 253058357,
      isDeleted: false,
      id: '0A87OSDU8c7MVyQj7Lc2b',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 2.1995926132103403,
      x: 1807.4490198782232,
      y: -425.7763655681829,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 12.336376087944927,
      height: 9.132122558608614,
      seed: 510054396,
      groupIds: ['2ArzPtehg-CfKfLYejpqp', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-3.8451042352035074, -3.3644662058031827],
        [-0.6408507058672512, -6.568719735139496],
        [3.7154699533198254, -3.2423145750173035],
        [5.126805646938237, -7.049357764539991],
        [8.49127185274142, -4.165529588137247],
        [7.369783117473617, 2.0827647940686234],
      ],
    },
    {
      type: 'line',
      version: 1025,
      versionNonce: 1597894293,
      isDeleted: false,
      id: 'HprEBSNYhfPOHGp8kWdkh',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.2345540333327536,
      x: 1764.2388443439786,
      y: -460.72197646063864,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 12.722903642720894,
      height: 18.344651763923082,
      seed: 177505860,
      groupIds: ['Ls7AYVLXaoTwTrams_89J', 'PJg-Z_AiyHWudT7GrRnlE'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707050688503,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [1.0355851802214602, 7.101155521518592],
        [-2.9588148006328083, 10.059970322151344],
        [-6.50939256139236, 9.616148102056457],
        [-4.586162940981012, 5.621748121202188],
        [-8.136740701740337, 4.142340720885841],
        [-9.024385141929997, 1.1835259202530892],
        [-6.213511081328988, -1.183525920253146],
        [-11.39143698243629, -2.2191111004745494],
        [-11.687318462499434, -6.657333301423762],
        [-7.397037001582248, -8.284681441771738],
        [-2.367051840506292, -6.95321478148702],
      ],
    },
  ],
  files: {},
}
export default obj
