import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

//@ts-ignore
const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 33,
      versionNonce: 1367901468,
      isDeleted: false,
      id: 'drDDgTeR5sdJ0_gEo-nig',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2002.628087349227,
      y: 382.940517551619,
      strokeColor: '#f08c00',
      backgroundColor: '#ffec99',
      width: 60,
      height: 4,
      seed: 1149105534,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [60, 0],
        [56, -4],
        [4, -4],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 1292036772,
      isDeleted: false,
      id: '1mdGGtABuycLcI0rWz9zB',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2002.628087349227,
      y: 382.940517551619,
      strokeColor: '#f08c00',
      backgroundColor: '#ffec99',
      width: 60,
      height: 40,
      seed: 643732926,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [60, 0],
        [50, 40],
        [10, 40],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 686915996,
      isDeleted: false,
      id: 'qbfx0_jSMf51nva8VKxGe',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2014.628087349227,
      y: 418.940517551619,
      strokeColor: '#f08c00',
      backgroundColor: '#fff9db',
      width: 52,
      height: 32,
      seed: 2076257790,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [36, 0],
        [44, -32],
        [-8, -32],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 974517796,
      isDeleted: false,
      id: 'rUNnWibzBEhqt22bomy7R',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2032.628087349227,
      y: 384.940517551619,
      strokeColor: '#ffec99',
      backgroundColor: 'transparent',
      width: 0,
      height: 34,
      seed: 1906753086,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 34],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 319221276,
      isDeleted: false,
      id: 'ul3gB9f4ATYa5wyLPpi1a',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2018.628087349227,
      y: 386.940517551619,
      strokeColor: '#ffec99',
      backgroundColor: 'transparent',
      width: 4,
      height: 32,
      seed: 1827686014,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [4, 32],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 935039396,
      isDeleted: false,
      id: 'uBiip8KAPZ92qS60r9wfg',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2032.6046502216095,
      y: 385.9227162910944,
      strokeColor: '#ffec99',
      backgroundColor: 'transparent',
      width: 0,
      height: 34,
      seed: 241092286,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 34],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 1622854300,
      isDeleted: false,
      id: 'johVDNUr_dTE3YyYS8iFm',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2046.628087349227,
      y: 386.940517551619,
      strokeColor: '#ffec99',
      backgroundColor: 'transparent',
      width: 4,
      height: 32,
      seed: 915619582,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-4, 32],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 891403556,
      isDeleted: false,
      id: 'zkRqtioTRCDtWOKHeRkkN',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2010.2345819382017,
      y: 404.3030725154375,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 44.94991870695412,
      height: 0,
      seed: 168734526,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [44.94991870695412, 0],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 1958680348,
      isDeleted: false,
      id: 'qp8PlJmtH7Tbp-r_q9X97',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2007.5807121990456,
      y: 395.9294191798318,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 48.76485645699063,
      height: 0,
      seed: 1966217086,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [48.76485645699063, 0],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 196507812,
      isDeleted: false,
      id: 'cYMaDrinI4rJPUB9s8sNu',
      fillStyle: 'solid',
      strokeWidth: 2,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2012.7225848186604,
      y: 412.3788563059753,
      strokeColor: '#ffec99',
      backgroundColor: '#ffec99',
      width: 39.47631236994471,
      height: 0,
      seed: 809400254,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [39.47631236994471, 0],
      ],
    },
    {
      type: 'line',
      version: 33,
      versionNonce: 193319836,
      isDeleted: false,
      id: 'ULA0vUwP1pUqjgy5f9Yj7',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 2014.628087349227,
      y: 418.940517551619,
      strokeColor: '#f08c00',
      backgroundColor: 'transparent',
      width: 52,
      height: 32,
      seed: 1129631742,
      groupIds: ['7eURik-ZCZtQN0X3AV9Jq'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1713605438265,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [36, 0],
        [44, -32],
        [-8, -32],
        [0, 0],
      ],
    },
  ],
  files: {},
}

export default obj
