import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 1773,
      versionNonce: 262831515,
      isDeleted: false,
      id: 'v-vWuv7RuUPJnuw6dkDbV',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 780,
      y: -112.00761869051848,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 10.372381866162458,
      height: 28.150082208692915,
      seed: 1333165706,
      groupIds: ['N51CjyyTefsZeuBG250Eh'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707048721964,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0.026822216218238104, -3.92281302908471],
        [0.07187677642523577, -16.912458454707117],
        [0.8336576795253271, -20.456051813899844],
        [2.9322280761945176, -23.385110849640345],
        [3.292664557849957, -24.636963087793347],
        [6.838152746138767, -24.603991426613625],
        [7.3513646346655666, -23.278259587491547],
        [9.757893296463712, -20.152240169188374],
        [10.231222489781608, -16.227321566187705],
        [10.372381866162458, -1.676825815625294],
        [9.002151932013735, 3.4767624283700425],
        [1.1398965987766012, 3.5131191208995642],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 447,
      versionNonce: 643699259,
      isDeleted: false,
      id: 'Cyn1RwUnqd60QEf-uVnmT',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 780.0783540833747,
      y: -127.62810044744668,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 9.951310509662505,
      height: 0,
      seed: 1301929034,
      groupIds: ['N51CjyyTefsZeuBG250Eh'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707048721964,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [9.951310509662505, 0],
      ],
    },
    {
      type: 'line',
      version: 526,
      versionNonce: 385225435,
      isDeleted: false,
      id: 'G4408TzR2huZ_Ncd1EsRi',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 780.1590403848043,
      y: -115.95548217395071,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 10.290067374285092,
      height: 0,
      seed: 1311784150,
      groupIds: ['N51CjyyTefsZeuBG250Eh'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707048721964,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [10.290067374285092, 0],
      ],
    },
    {
      type: 'rectangle',
      version: 360,
      versionNonce: 33881979,
      isDeleted: false,
      id: 'kfcveEyt8AwET--nCGcp3',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 782.4638785511233,
      y: -140,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 5.325295894359945,
      height: 3.49640639528681,
      seed: 320603466,
      groupIds: ['N51CjyyTefsZeuBG250Eh'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048721964,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
