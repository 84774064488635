import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'ellipse',
      version: 33,
      versionNonce: 240286866,
      isDeleted: false,
      id: 'CqJ9F_ou3yqU_uuM_2zTD',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 0,
      angle: 0,
      x: 800,
      y: 0,
      strokeColor: '#ced4da',
      backgroundColor: '#d2bab0',
      width: 50,
      height: 50,
      seed: 866339662,
      groupIds: ['UWlcUBGFVP51pAzqWxla-'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707076350675,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 97,
      versionNonce: 328125838,
      isDeleted: false,
      id: '8SUW0Ryq5HWDyZIrY2RrN',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 808.9945087547777,
      y: 8.994508754777712,
      strokeColor: '#1e1e1e',
      backgroundColor: '#69db7c',
      width: 32.010982490444576,
      height: 32.010982490444576,
      seed: 1744351630,
      groupIds: ['UWlcUBGFVP51pAzqWxla-'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707076350675,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 98,
      versionNonce: 1270422098,
      isDeleted: false,
      id: 'qKkLsk3bdGVxpua-rZkek',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 812.4537650330255,
      y: 15.1187273190492,
      strokeColor: '#1e1e1e',
      backgroundColor: '#69db7c',
      width: 25.08074309407383,
      height: 0,
      seed: 826757070,
      groupIds: ['UWlcUBGFVP51pAzqWxla-'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707076350675,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [25.08074309407383, 0],
      ],
    },
    {
      type: 'line',
      version: 98,
      versionNonce: 1002040270,
      isDeleted: false,
      id: 'bl5QUnaNix_My9sTt4xgm',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 809.8306964917825,
      y: 19.956090555565364,
      strokeColor: '#1e1e1e',
      backgroundColor: '#69db7c',
      width: 30.27906650658325,
      height: 0,
      seed: 195569166,
      groupIds: ['UWlcUBGFVP51pAzqWxla-'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707076350675,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [30.27906650658325, 0],
      ],
    },
  ],
  files: {},
}
export default obj
