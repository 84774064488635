import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 89,
      versionNonce: 890370261,
      isDeleted: false,
      id: '7hqKqg-wGXGZfkr5otemU',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1080,
      y: 10,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 60,
      height: 60,
      seed: 1048318602,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 82,
      versionNonce: 1472621307,
      isDeleted: false,
      id: 'qGG7ITqxcoArKb9OQ4Oaj',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1110,
      y: 10,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 0,
      height: 60,
      seed: 143860490,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 60],
      ],
    },
    {
      type: 'line',
      version: 82,
      versionNonce: 1046877749,
      isDeleted: false,
      id: 'iosZYUaoCjqUt90SsmFzG',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1080,
      y: 40,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffa1a1',
      width: 60,
      height: 0,
      seed: 458543690,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [60, 0],
      ],
    },
    {
      type: 'ellipse',
      version: 137,
      versionNonce: 1398100891,
      isDeleted: false,
      id: 'U__dR1mSPy724qB_tvd5s',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1088.071975796922,
      y: 17.68637095630664,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 14.434455667079021,
      height: 14.434455667079021,
      seed: 2123128266,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 90,
      versionNonce: 906987413,
      isDeleted: false,
      id: 'nSkpQ3lYhnWFrwwZUVg6P',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1120,
      y: 20,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 10,
      height: 10,
      seed: 1592432150,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 117,
      versionNonce: 440281147,
      isDeleted: false,
      id: 'VZNeePODbN3xZgVuqX-eI',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1090,
      y: 50,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 10,
      height: 10,
      seed: 10183510,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 173,
      versionNonce: 1313066229,
      isDeleted: false,
      id: 'g0cFrGGJ_XeMcVR83wTSf',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1117.8458881522943,
      y: 47.88796547618388,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 14.434455667079021,
      height: 14.434455667079021,
      seed: 1032824330,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 90,
      versionNonce: 40069339,
      isDeleted: false,
      id: 'FoXf9W_nvzqDQIZ2WUlwk',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1139.689850774721,
      y: 25,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 2048829322,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 133,
      versionNonce: 1451779669,
      isDeleted: false,
      id: 'SD--1KFe7ElWHQTW_lS6S',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1123.248268680923,
      y: 25,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 468807574,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 196,
      versionNonce: 22517115,
      isDeleted: false,
      id: '-jrIKT3t9fcUfKjNlcxpE',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1109.3903637732938,
      y: 25,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1033788182,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 246,
      versionNonce: 296932277,
      isDeleted: false,
      id: 'ZKPgClixYndcCLFeLZFPs',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1093.4185411678902,
      y: 25,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 113205578,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 157,
      versionNonce: 912076315,
      isDeleted: false,
      id: 'BAMr5KLbvmI83N-u02qzz',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1139.924730518918,
      y: 55,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1902066390,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 200,
      versionNonce: 1273533717,
      isDeleted: false,
      id: 'uWVxysfz-N43TiXHWsS3_',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1123.4831484251201,
      y: 55,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1318940694,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 263,
      versionNonce: 879145659,
      isDeleted: false,
      id: 'PJ4c55aKDqBXX_6M9nhqT',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1109.6252435174908,
      y: 55,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1759706454,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 313,
      versionNonce: 815737461,
      isDeleted: false,
      id: 'TQKlfpq0lvo2yJ7EV7SJK',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1093.6534209120875,
      y: 55,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1434772118,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 221,
      versionNonce: 202810203,
      isDeleted: false,
      id: 'Nk_X4_9Azn-lC7RulP87M',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.5707963267948957,
      x: 1101.602323284674,
      y: 32.96661962728603,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1834465034,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 271,
      versionNonce: 1620841429,
      isDeleted: false,
      id: 'yslygtbJJnzL5YI0FNEim',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.5707963267948957,
      x: 1101.602323284674,
      y: 16.99479702188228,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1679836618,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 309,
      versionNonce: 364871675,
      isDeleted: false,
      id: 'UzhlcYwK9unQ_aNgi_TTL',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.5707963267948957,
      x: 1131.5766328375191,
      y: 32.909451501512066,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1997870858,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 359,
      versionNonce: 1207226677,
      isDeleted: false,
      id: 'hk8O8ppi6RY7WgKvoZ7RP',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.5707963267948957,
      x: 1131.5766328375191,
      y: 16.937628896108315,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 254911946,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 353,
      versionNonce: 2070136987,
      isDeleted: false,
      id: 'byjE-Ic75TtjXiaOFPmtA',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.5707963267948957,
      x: 1131.5797847819908,
      y: 62.97110486740465,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 141779658,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 403,
      versionNonce: 873390741,
      isDeleted: false,
      id: 'liZQg1nh5h2T6HCip88O9',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.5707963267948957,
      x: 1131.5797847819908,
      y: 46.99928226200091,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1045461386,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 357,
      versionNonce: 45481275,
      isDeleted: false,
      id: 'f3YVFlPE8eBP58vzB3ooy',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.5707963267948957,
      x: 1101.5766328375191,
      y: 62.59208036667589,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1024344790,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
    {
      type: 'line',
      version: 407,
      versionNonce: 698337269,
      isDeleted: false,
      id: '0Xdz2QPoqbOn-l5U5SsF-',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 1.5707963267948957,
      x: 1101.5766328375191,
      y: 46.620257761272114,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 13.153265675038256,
      height: 0,
      seed: 1596684310,
      groupIds: ['OD3wkFdJCBwQH1MaOouc_'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707047725566,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-13.153265675038256, 0],
      ],
    },
  ],
  files: {},
}
export default obj
