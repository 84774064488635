import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'ellipse',
      version: 192,
      versionNonce: 1645647963,
      isDeleted: false,
      id: 'LhIYWhm8nIDXX9CE1aFUW',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 770,
      y: -180,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 22.378947482041667,
      height: 22.378947482041667,
      seed: 1448526166,
      groupIds: ['yG7ul0xuTyDA23nUF4MAb'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707048721964,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 195,
      versionNonce: 1912132859,
      isDeleted: false,
      id: 'nS8G-VJ43XoZhmL1mEAxG',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 775.5947368705104,
      y: -174.40526312948958,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 11.189473741020834,
      height: 11.189473741020834,
      seed: 1902901526,
      groupIds: ['yG7ul0xuTyDA23nUF4MAb'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707048721964,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
