import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'ellipse',
      version: 14,
      versionNonce: 1228307007,
      isDeleted: false,
      id: 'ZNaHl8_So37ogEvDBwhdZ',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 0,
      angle: 0,
      x: 1000.0212990081262,
      y: 150.02129900812614,
      strokeColor: '#ced4da',
      backgroundColor: '#d2bab0',
      width: 50,
      height: 50,
      seed: 1561640179,
      groupIds: ['gojy-1q9vmdMgke8hc-0V'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1707076720932,
      link: null,
      locked: false,
    },
    {
      type: 'ellipse',
      version: 14,
      versionNonce: 1731497265,
      isDeleted: false,
      id: 'GC_xrSQKYx6Bz8-AY9BsH',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1009.0158077629039,
      y: 159.01580776290385,
      strokeColor: '#1e1e1e',
      backgroundColor: '#d2bab0',
      width: 32.010982490444576,
      height: 32.010982490444576,
      seed: 670475923,
      groupIds: ['gojy-1q9vmdMgke8hc-0V'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707076720932,
      link: null,
      locked: false,
    },
    {
      type: 'line',
      version: 93,
      versionNonce: 1040898655,
      isDeleted: false,
      id: 'PdYnoCY4fAoApaF3XSYgy',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1011.865378209245,
      y: 166.37284565904898,
      strokeColor: '#1e1e1e',
      backgroundColor: '#d2bab0',
      width: 26.017385557107673,
      height: 0,
      seed: 1805016531,
      groupIds: ['gojy-1q9vmdMgke8hc-0V'],
      frameId: null,
      roundness: { type: 2 },
      boundElements: [],
      updated: 1707076720932,
      link: null,
      locked: false,

      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [26.017385557107673, 0],
      ],
    },
  ],
  files: {},
}
export default obj
