import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

//@ts-ignore
const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'line',
      version: 1408,
      versionNonce: 2099774045,
      isDeleted: false,
      id: 'soDGgwaeQhhx2k2hvdf4M',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 904.5473409983539,
      y: 196.04529148563228,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 390.52476257217404,
      height: 4.446202179162583,
      seed: 1902753293,
      groupIds: ['JxIopfY9LROxxe6nT2h7E', 'QeaLQjq3rFnbD_IBKUuH5', 'YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 4.446202179162583],
        [390.52476257217404, 4.446202179162583],
        [390.50130725327176, 0.4819754529396505],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1131,
      versionNonce: 1259707891,
      isDeleted: false,
      id: 'uCaLV0ildVFgVbNpRXZKj',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 900,
      y: 195,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.5192588694310984,
      height: 6.536785150427079,
      seed: 820125677,
      groupIds: ['JxIopfY9LROxxe6nT2h7E', 'QeaLQjq3rFnbD_IBKUuH5', 'YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [4.5192588694310984, 0],
        [4.5192588694310984, 6.536785150427079],
        [0.08070105123982785, 6.536785150427079],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1257,
      versionNonce: 1360113341,
      isDeleted: false,
      id: '5Q7EerE5oRcUptRvaEIUc',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1294.9858891298838,
      y: 195,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.5192588694310984,
      height: 6.536785150427079,
      seed: 311367683,
      groupIds: ['JxIopfY9LROxxe6nT2h7E', 'QeaLQjq3rFnbD_IBKUuH5', 'YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [4.5192588694310984, 0],
        [4.5192588694310984, 6.536785150427079],
        [0.08070105123982785, 6.536785150427079],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1527,
      versionNonce: 1160996755,
      isDeleted: false,
      id: 'FenHv05o6h0r05bWfR63V',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 904.5473409983539,
      y: 229.55494225947797,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 390.52476257217404,
      height: 4.446202179162583,
      seed: 1047103725,
      groupIds: ['4FGn7lrdzvQasE19gJJtb', 'QeaLQjq3rFnbD_IBKUuH5', 'YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 4.446202179162583],
        [390.52476257217404, 4.446202179162583],
        [390.50130725327176, 0.4819754529396505],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1250,
      versionNonce: 1741082397,
      isDeleted: false,
      id: 'rMB-Uk4LA4NGJBN9JUyZ3',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 900,
      y: 228.50965077384564,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.5192588694310984,
      height: 6.536785150427079,
      seed: 1535370061,
      groupIds: ['4FGn7lrdzvQasE19gJJtb', 'QeaLQjq3rFnbD_IBKUuH5', 'YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [4.5192588694310984, 0],
        [4.5192588694310984, 6.536785150427079],
        [0.08070105123982785, 6.536785150427079],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1376,
      versionNonce: 2015821107,
      isDeleted: false,
      id: 'GL5GpNPPlc5ZlDDO0VeGu',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1294.985889129884,
      y: 228.50965077384564,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.5192588694310984,
      height: 6.536785150427079,
      seed: 1831072173,
      groupIds: ['4FGn7lrdzvQasE19gJJtb', 'QeaLQjq3rFnbD_IBKUuH5', 'YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [4.5192588694310984, 0],
        [4.5192588694310984, 6.536785150427079],
        [0.08070105123982785, 6.536785150427079],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 860,
      versionNonce: 1069081469,
      isDeleted: false,
      id: 't1ZBGTe5Nwg8diF1WbHhi',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 920,
      y: 200.65114619272873,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.051351587884824,
      height: 28.939993621386407,
      seed: 1213594147,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 28.869025830565533],
        [4.028591733583994, 28.869025830565533],
        [4.051351587884824, -0.07096779082087323],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 931,
      versionNonce: 1599528659,
      isDeleted: false,
      id: 'y6GiYkfw3HaWdiAAlphhm',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1275.8349242751924,
      y: 200.65114619272873,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.051351587884824,
      height: 28.939993621386407,
      seed: 1394840803,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 28.869025830565533],
        [4.028591733583994, 28.869025830565533],
        [4.051351587884824, -0.07096779082087323],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 989,
      versionNonce: 220818397,
      isDeleted: false,
      id: 'MrgYFO4iOSk4YoYhRr9gz',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1097.9174621375962,
      y: 200.65114619272873,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.051351587884824,
      height: 28.939993621386407,
      seed: 1231231789,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 28.869025830565533],
        [4.028591733583994, 28.869025830565533],
        [4.051351587884824, -0.07096779082087323],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1226,
      versionNonce: 1163496563,
      isDeleted: false,
      id: 's7m0ey5dd_hoXQJWmkqkE',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1038.6116414250641,
      y: 200.65114619272873,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.051351587884824,
      height: 28.939993621386407,
      seed: 890520707,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 28.869025830565533],
        [4.028591733583994, 28.869025830565533],
        [4.051351587884824, -0.07096779082087323],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1205,
      versionNonce: 2746429,
      isDeleted: false,
      id: '0adRqpZDc_oxJVPUpae7y',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1216.5291035626603,
      y: 200.65114619272873,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.051351587884824,
      height: 28.939993621386407,
      seed: 669378253,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 28.869025830565533],
        [4.028591733583994, 28.869025830565533],
        [4.051351587884824, -0.07096779082087323],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1278,
      versionNonce: 1576371731,
      isDeleted: false,
      id: 'LZm61D--CtRz_J870LzW3',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 979.3058207125321,
      y: 200.65114619272873,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.051351587884824,
      height: 28.939993621386407,
      seed: 311496131,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 28.869025830565533],
        [4.028591733583994, 28.869025830565533],
        [4.051351587884824, -0.07096779082087323],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1224,
      versionNonce: 1251727517,
      isDeleted: false,
      id: 'WZZB8V87Qdb88X1KlIMMS',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1157.2232828501283,
      y: 200.65114619272873,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 4.051351587884824,
      height: 28.939993621386407,
      seed: 1393471565,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0, 28.869025830565533],
        [4.028591733583994, 28.869025830565533],
        [4.051351587884824, -0.07096779082087323],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1186,
      versionNonce: 539052979,
      isDeleted: false,
      id: 'pnbQPHEQ5VcrgiPl3fYMP',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 949.6750633810086,
      y: 229.61020666924026,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 29.112148784971055,
      seed: 1553049507,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.7697152009336605, 0],
        [-25.614760447541585, -26.21659863852119],
        [-25.648093780875, -29.108181155214254],
        [-22.735563043912407, -29.087232934944666],
        [1.9252070978238862, -2.9213850919659876],
        [1.9080808834123673, 0.003967629756800761],
        [-0.7402038734584266, 0],
      ],
    },
    {
      type: 'line',
      version: 1406,
      versionNonce: 1714470141,
      isDeleted: false,
      id: 'giasfmvkbsXl5v2ND09fW',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 954.4040004185938,
      y: 229.58423244290026,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 28.912148784971066,
      seed: 1351683331,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0.029511327475233884, -5.684341886080802e-14],
        [24.874556574083158, -26.016598638521202],
        [24.907889907416575, -28.908181155214265],
        [21.99535917045398, -28.887232934944677],
        [-2.665410971282313, -2.721385091965999],
        [-2.648284756870794, 0.003967629756800761],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1230,
      versionNonce: 1610833235,
      isDeleted: false,
      id: '3I6-44x7VkoaMNvZc7q9_',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1008.977700227493,
      y: 229.6369155676881,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 29.112148784971055,
      seed: 2060729315,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.7697152009336605, 0],
        [-25.614760447541585, -26.21659863852119],
        [-25.648093780875, -29.108181155214254],
        [-22.735563043912407, -29.087232934944666],
        [1.9252070978238862, -2.9213850919659876],
        [1.9080808834123673, 0.003967629756800761],
        [-0.7402038734584266, 0],
      ],
    },
    {
      type: 'line',
      version: 1450,
      versionNonce: 443159901,
      isDeleted: false,
      id: 'q8CtqDN6BvCV2CqeN8HnY',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1013.706637265078,
      y: 229.61094134134805,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 28.912148784971066,
      seed: 1363724163,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0.029511327475233884, -5.684341886080802e-14],
        [24.874556574083158, -26.016598638521202],
        [24.907889907416575, -28.908181155214265],
        [21.99535917045398, -28.887232934944677],
        [-2.665410971282313, -2.721385091965999],
        [-2.648284756870794, 0.003967629756800761],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1265,
      versionNonce: 686963443,
      isDeleted: false,
      id: 'npW6tljJJiQwK8CSeYxAD',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1127.6160116260353,
      y: 229.63904173235574,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 29.112148784971055,
      seed: 1336958957,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.7697152009336605, 0],
        [-25.614760447541585, -26.21659863852119],
        [-25.648093780875, -29.108181155214254],
        [-22.735563043912407, -29.087232934944666],
        [1.9252070978238862, -2.9213850919659876],
        [1.9080808834123673, 0.003967629756800761],
        [-0.7402038734584266, 0],
      ],
    },
    {
      type: 'line',
      version: 1485,
      versionNonce: 1117348285,
      isDeleted: false,
      id: 'ImoGcblGE6euqRrnQSWgz',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1132.3449486636205,
      y: 229.6130675060158,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 28.912148784971066,
      seed: 1809308237,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0.029511327475233884, -5.684341886080802e-14],
        [24.874556574083158, -26.016598638521202],
        [24.907889907416575, -28.908181155214265],
        [21.99535917045398, -28.887232934944677],
        [-2.665410971282313, -2.721385091965999],
        [-2.648284756870794, 0.003967629756800761],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1217,
      versionNonce: 1467245715,
      isDeleted: false,
      id: 'JXvggjMMYMmexA2tIRrW5',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1068.3400121199134,
      y: 229.63618124980428,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 29.112148784971055,
      seed: 1950827619,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.7697152009336605, 0],
        [-25.614760447541585, -26.21659863852119],
        [-25.648093780875, -29.108181155214254],
        [-22.735563043912407, -29.087232934944666],
        [1.9252070978238862, -2.9213850919659876],
        [1.9080808834123673, 0.003967629756800761],
        [-0.7402038734584266, 0],
      ],
    },
    {
      type: 'line',
      version: 1437,
      versionNonce: 1407197725,
      isDeleted: false,
      id: 'hCjYSXfgqhEiXSV7Bw0W0',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1073.0689491574985,
      y: 229.61020702346423,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 28.912148784971066,
      seed: 675725315,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0.029511327475233884, -5.684341886080802e-14],
        [24.874556574083158, -26.016598638521202],
        [24.907889907416575, -28.908181155214265],
        [21.99535917045398, -28.887232934944677],
        [-2.665410971282313, -2.721385091965999],
        [-2.648284756870794, 0.003967629756800761],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1224,
      versionNonce: 1933597235,
      isDeleted: false,
      id: 'IbAXCzp-N_rezRHTuHVtn',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1186.927203694794,
      y: 229.7582896426482,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 29.112148784971055,
      seed: 388319779,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.7697152009336605, 0],
        [-25.614760447541585, -26.21659863852119],
        [-25.648093780875, -29.108181155214254],
        [-22.735563043912407, -29.087232934944666],
        [1.9252070978238862, -2.9213850919659876],
        [1.9080808834123673, 0.003967629756800761],
        [-0.7402038734584266, 0],
      ],
    },
    {
      type: 'line',
      version: 1444,
      versionNonce: 590337661,
      isDeleted: false,
      id: '6LWZ7iBlkev0M-Uotc0px',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1191.656140732379,
      y: 229.73231541630815,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 28.912148784971066,
      seed: 1423838659,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0.029511327475233884, -5.684341886080802e-14],
        [24.874556574083158, -26.016598638521202],
        [24.907889907416575, -28.908181155214265],
        [21.99535917045398, -28.887232934944677],
        [-2.665410971282313, -2.721385091965999],
        [-2.648284756870794, 0.003967629756800761],
        [0, 0],
      ],
    },
    {
      type: 'line',
      version: 1233,
      versionNonce: 1228708819,
      isDeleted: false,
      id: '59JjzqSs3tfSXUBwJ_-qd',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1246.2195667411852,
      y: 229.86867804046284,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 29.112148784971055,
      seed: 1917521859,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [-0.7697152009336605, 0],
        [-25.614760447541585, -26.21659863852119],
        [-25.648093780875, -29.108181155214254],
        [-22.735563043912407, -29.087232934944666],
        [1.9252070978238862, -2.9213850919659876],
        [1.9080808834123673, 0.003967629756800761],
        [-0.7402038734584266, 0],
      ],
    },
    {
      type: 'line',
      version: 1453,
      versionNonce: 1800011485,
      isDeleted: false,
      id: '74RdieEuAQjJvnYsdSx1N',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1250.9485037787704,
      y: 229.84270381412279,
      strokeColor: '#1e1e1e',
      backgroundColor: '#868e96',
      width: 27.573300878698888,
      height: 28.912148784971066,
      seed: 2121932643,
      groupIds: ['YgurEv71Fqu7YEJXjK3R6'],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: 1712857972269,
      link: null,
      locked: false,
      startBinding: null,
      endBinding: null,
      lastCommittedPoint: null,
      startArrowhead: null,
      endArrowhead: null,
      points: [
        [0, 0],
        [0.029511327475233884, -5.684341886080802e-14],
        [24.874556574083158, -26.016598638521202],
        [24.907889907416575, -28.908181155214265],
        [21.99535917045398, -28.887232934944677],
        [-2.665410971282313, -2.721385091965999],
        [-2.648284756870794, 0.003967629756800761],
        [0, 0],
      ],
    },
  ],
  files: {},
}
export default obj
