import { ElementsClipboard } from '../../../../packages/excalidraw/clipboard'

const obj: ElementsClipboard = {
  type: 'excalidraw/clipboard',
  elements: [
    {
      type: 'rectangle',
      version: 237,
      versionNonce: 468315931,
      isDeleted: false,
      id: 'Qcj5Y-8fPuHPP6fMs9Sn3',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1318.7210754966395,
      y: -326.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 60,
      height: 60,
      seed: 1873047381,
      groupIds: ['X9i9qJCdJT9o6MUyI9zcM'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048715808,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 249,
      versionNonce: 1665426453,
      isDeleted: false,
      id: '8SjRX5NEHuja-flRwYIdY',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1258.7210754966395,
      y: -326.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 60,
      height: 60,
      seed: 1772384155,
      groupIds: ['X9i9qJCdJT9o6MUyI9zcM'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048715808,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 245,
      versionNonce: 749949883,
      isDeleted: false,
      id: '3yV_vfb7ZuHTiT7cqqUl_',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1198.7210754966395,
      y: -326.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 60,
      height: 60,
      seed: 1022068373,
      groupIds: ['X9i9qJCdJT9o6MUyI9zcM'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048715808,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 297,
      versionNonce: 698947957,
      isDeleted: false,
      id: 'ELpKHDwsreAX0sCoBWHeR',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1194.7210754966395,
      y: -316.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 15.765365448229772,
      height: 43.64804817234466,
      seed: 838586293,
      groupIds: ['X9i9qJCdJT9o6MUyI9zcM'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048715808,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 280,
      versionNonce: 1948722267,
      isDeleted: false,
      id: 'TV7CTl24GQ0_uWFPBIXMw',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1366.7210754966395,
      y: -316.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 15.765365448229772,
      height: 43.64804817234466,
      seed: 1668917973,
      groupIds: ['X9i9qJCdJT9o6MUyI9zcM'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048715808,
      link: null,
      locked: false,
    },
    {
      type: 'rectangle',
      version: 328,
      versionNonce: 546317013,
      isDeleted: false,
      id: '7A6s-E0Js0OA-cjTKc8vp',
      fillStyle: 'solid',
      strokeWidth: 1,
      strokeStyle: 'solid',
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: 1198.7210754966395,
      y: -326.72474045414776,
      strokeColor: '#1e1e1e',
      backgroundColor: '#ffc9c9',
      width: 180.00000000000003,
      height: 20,
      seed: 1417114715,
      groupIds: ['X9i9qJCdJT9o6MUyI9zcM'],
      frameId: null,
      roundness: { type: 3 },
      boundElements: [],
      updated: 1707048715808,
      link: null,
      locked: false,
    },
  ],
  files: {},
}
export default obj
